<template>
  <div>
    <div class="card-form container" id="print">
      <div class="mb-5 w-100 d-flex justify-content-between">
        <div>
          <img
            alt="Logo"
            :src="layoutConfig('self.logo.secundary')"
            class="max-h-40px"
          />
        </div>
        <div class="mt-4">
          <span
            >Impressão - <label>{{ title }}</label></span
          >
          <br />
        </div>
      </div>
      <div class="col-12">
        <b-card title="" tag="article" class="mb-2 border border-primary">
          <b-card-text>
            <b-table
              v-if="data.length > 0"
              striped
              small
              hover
              :items="tableItems"
              :fields="tableFields"
            />
          </b-card-text>
          <div v-if="totalSum > 0" class="d-flex justify-content-end mt-3">
            <strong>Total: {{ numberToReal(totalSum) }}</strong>
          </div>
        </b-card>
      </div>
      <div class="mt-5 w-100 d-flex justify-content-end">
        <img
          alt="Logo"
          :src="layoutConfig('self.logo.icone')"
          class="max-h-30px mr-5"
        />
      </div>
    </div>
    <div class="mt-5 w-100 d-flex justify-content-center d-print-none">
      <button
        @click="printDocument"
        class="btn btn-success btn-hover-primary mr-2"
      >
        Imprimir
      </button>
      <button
        @click="$router.go(-1)"
        class="btn btn-success btn-hover-primary mr-2"
      >
        Voltar
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import moment from "moment";

export default {
  name: "app-visualizacao-impressao",
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  directives: {
    mask,
  },
  components: {},
  data() {
    return {
      allStatus: [],
      data: [],
      labels: [],
      title: null,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
    tableFields() {
      return this.labels
        .filter((label) => label.field !== "actions")
        .map((label) => ({
          key: label.label,
          label: label.label,
          class: ["valor", "valorTotal", "total_nfse"].includes(label.field)
            ? "text-right"
            : "",
        }));
    },
    tableItems() {
      return this.data.map((item) => {
        console.log(this.labels);
        const filteredLabels = this.labels.filter(
          (label) => label.field !== "actions"
        );
        const row = filteredLabels.reduce(
          (acc, label) => ({
            ...acc,
            ...(label.field !== "actions" && {
              [label.label]:
                label.field !== "status" &&
                !item[label.field] &&
                this.title !== "Cadastro de Produtos"
                  ? "-"
                  : label.type === "date"
                  ? moment(item[label.field]).format("DD/MM/YYYY")
                  : label.field === "status"
                  ? item[label.field] === undefined
                    ? this.statusByDate(item)
                    : this.title === "Ordem de Compra"
                    ? this.statusOrdemCompraLabel(item[label.field])
                    : this.title === "Pessoas"
                    ? this.statusLabelPessoa(item[label.field])
                    : this.title === "NFe"
                    ? this.statusLabel(item.statusTransmissao)
                    : this.title === "Vendas Realizadas"
                    ? this.statusLabel(item.status)
                    : this.title === "Monitor NFe"
                    ? this.statusMonitorLabel(item.status)
                    : this.title === "Pedidos"
                    ? this.statusPedidoLabel(item.status)
                    : this.title === "Orçamentos"
                    ? this.statusPedidoLabel(item.status)
                    : this.title === "Ordem de Produção"
                    ? this.statusProducaoLabel(item.status)
                    : this.title === "Ordem de Serviço"
                    ? this.statusOSLabel(item.status)
                    : this.title === "MDFe"
                    ? this.statusMdfeLabel(item.status)
                    : this.title === "Contratos"
                    ? this.statusContratosServicosLabel(item.status)
                    : this.statusLabel(item[label.field])
                  : label.field === "valorTotal" ||
                    label.field === "valor" ||
                    label.field === "valor_total" ||
                    label.field === "preco" ||
                    label.field === "custo" ||
                    label.field === "total_nfse"
                  ? this.numberToReal(item[label.field], 2)
                  : label.field === "chave"
                  ? `${item[label.field].slice(1, 10)}...`
                  : item[label.field] === undefined
                  ? item[label.field.split(".")[1]]
                  : label.field === "fones"
                  ? this.formatFones(item[label.field])
                  : label.field === "enderecos"
                  ? this.formatEndereco(item[label.field])
                  : label.field === "fornecedor" &&
                    this.title === "Compras Realizadas"
                  ? this.formatNomeFornecedor(item.fornecedor)
                  : label.field === "dataEmissao" ||
                    label.field === "dataEntrada" ||
                    label.field === "dataValidade"
                  ? moment(item[label.field]).format("DD/MM/YYYY")
                  : label.field === "vendedor"
                  ? this.formatNomeVendedor(item[label.field])
                  : label.field === "produto"
                  ? this.formaPordutoProducao(item[label.field])
                  : label.field === "identificadores"
                  ? this.formataIdentificadores(item[label.field])
                  : label.field === "tecnico"
                  ? this.formatTecnico(item[label.field])
                  : label.field === "objeto"
                  ? this.formatObjeto(item[label.field])
                  : item[label.field],
            }),
          }),
          {}
        );

        return row;
      });
    },
    totalSum() {
      // Liste aqui os campos que representam valores
      const valueFields = [
        "valorTotal",
        "valor",
        "preco",
        "custo",
        "total_nfse",
      ];
      return this.data.reduce((sum, item) => {
        valueFields.forEach((field) => {
          if (item[field] !== undefined && item[field] !== null) {
            const value = parseFloat(item[field]) || 0; // Converte para número

            // Verifica se item.tipo está definido
            if (item.tipo !== undefined) {
              if (item.tipo === "S") {
                sum -= value; // Subtrair se o tipo for 'S'
              } else if (item.tipo === "E") {
                sum += value; // Somar se o tipo for 'E'
              }
            } else {
              // Se item.tipo não estiver definido, soma o valor por padrão
              sum += value;
            }
          }
        });
        return sum;
      }, 0);
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getInit() {
      this.data = this.$route.params.data;
      this.labels = this.$route.params.labels;

      this.labels = [
        ...this.labels.filter(
          (label) =>
            !["valor", "valorTotal", "total_nfse"].includes(label.field)
        ), // Todos menos "valor" e "valorTotal"
        ...this.labels.filter((label) => label.field === "valor"),
        ...this.labels.filter((label) => label.field === "valorTotal"),
        ...this.labels.filter((label) => label.field === "total_nfse"),
      ];

      this.title = this.$route.params.title;
    },
    printDocument() {
      window.print();
    },
    numberToReal(value, decimals = 2) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: decimals,
      }).format(value || 0);
    },
    formataIdentificadores(identificadores) {
      const identificador = JSON.parse(identificadores);
      var identificadorFormatado = "";
      identificador.forEach((identifi) => {
        var valorIdentificador = "";
        if (identifi.value) {
          valorIdentificador = identifi.value;
        }
        identificadorFormatado +=
          identifi.descricao + ": " + valorIdentificador + ", ";
      });
      return identificadorFormatado;
    },
    formatObjeto(objeto) {
      return objeto ? `${objeto.descricao}` : "-";
    },

    formatTecnico(tecnico) {
      return tecnico ? `${tecnico.nome}` : "-";
    },
  },
  async mounted() {
    await this.getInit();
  },
  created() {},
};
</script>

<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: rgb(241, 249, 255);
}

.container {
  background-color: white;
  margin-top: 20px;
  border-radius: 10px;
  padding: 3rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  min-width: 100%;
}

.select-city .vs__search::placeholder,
.select-city .vs__dropdown-toggle,
.select-city .vs__dropdown-menu {
  height: 10rem !important;
  background-color: green !important;
}

.contato-input {
  height: 3rem;
}
.total-sum {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}
.text-right {
  text-align: right;
}
.table-column-valor {
  text-align: right;
}
</style>