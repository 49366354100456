<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'number',
          label: 'Número',
          field: 'numeroNFe',
        },
        {
          type: 'date',
          label: 'Data Entrada',
          field: 'dataEntrada',
        },
        {
          type: 'date',
          label: 'Data Emissão',
          field: 'dataEmissao',
        },
        {
          type: 'text',
          label: 'Fornecedor',
          field: 'fornecedor',
        },
      ]"
    />

    <!-- <button
      style="position: absolute; left: 110px; top: -1px"
      v-b-modal.modal-importar-xml
      class="btn btn-sm btn-warning btn-hover-danger btn-icon mr-2"
      v-b-tooltip="'Importar notas já importadas'"
    >
      <XmlIcon :size="16" />
    </button> -->
    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      v-if="actionsStore.selectedRows.length > 0"
      style="position: absolute; top: 0; left: 50px"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item
        @click="devolverNfe"
        :disabled="actionsStore.selectedRows.length > 1"
      >
        Devolver NFe
      </b-dropdown-item>
    </b-dropdown>
    <TableApp
      titulo="Compras Realizadas"
      subTitulo="Suas compras lançadas"
      acaoBtn="/#/compra-nova"
      textoBtn="Nova Compra"
      api-url="/compras"
      :fields="fields"
      classFilterColumn="compras"
      ref="compras"
      :perPage="30"
      secondaryButtonUrl="/#/compras/outros-lancamentos"
      secondaryButtonText="Outros lançamentos"
      :multiselect="true"
    >
      <template slot="customRow" scope="props">
        <span
          slot="numeroNFe"
          class="pl-0 pt-8"
          v-if="props.field == 'numeroNFe'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.numeroNFe }}
            </div>
          </div>
        </span>
        <span
          slot="numeroNFe"
          class="pl-0 pt-8"
          v-if="props.field == 'modeloNFe'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.modeloNFe }}
            </div>
          </div>
        </span>
        <span
          slot="fornecedor"
          class="pl-0 pt-8"
          v-if="props.field == 'fornecedor'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ formatFornecedor(props.rowData.fornecedor) }}
            </div>
          </div>
        </span>
        <span
          slot="dataEntrada"
          class="pl-0 pt-8"
          v-if="props.field == 'dataEntrada'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.dataEntrada | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>
        <span
          slot="dataEmissao"
          class="pl-0 pt-8"
          v-if="props.field == 'dataEmissao'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.dataEmissao | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>
        <span
          class="pl-0 pt-8"
          slot="chaveDeAcesso"
          v-if="props.field == 'chaveDeAcesso'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.chaveDeAcesso }}
            </div>
          </div>
        </span>
        <span
          slot="valor"
          v-if="props.field == 'valor'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          R$
          {{ numberToReal(props.rowData.valor, 2) }}
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <button
            v-if="
              props.rowData.modeloNFe == 55 &&
              !props.rowData.emitida_outro_software
            "
            @click="editCompra(props.rowData)"
            class="btn btn-sm btn-info btn-icon mr-2"
            v-b-tooltip.hover.bottom="'Editar Compra'"
          >
            <Pencil :size="16" />
          </button>
          <button
            v-if="props.rowData.arquivoXml"
            @click="gerarlXml(props.rowData)"
            class="btn btn-sm btn-warning btn-hover-warning btn-icon mr-2"
            v-b-tooltip.hover.bottom="'Gerar XML'"
          >
            <XMLIcon :size="16" />
          </button>
          <button
            v-if="props.rowData.arquivoXml"
            @click="gerarPDF(props.rowData)"
            class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
            v-b-tooltip.hover.bottom="'Imprimir Danfe'"
          >
            <PrintIcon :size="16" />
          </button>
          <button
            v-if="!props.rowData.emitida_outro_software"
            @click="excluir(props.rowData)"
            class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
            v-b-tooltip.hover.bottom="'Excluir'"
          >
            <TrashCanIcon :size="16" />
          </button>
        </span>
      </template>
    </TableApp>

    <b-modal
      id="modal-importar-xml"
      title="Importar Compras já importadas"
      size="lg"
      centered
    >
      <div class="mx-auto">
        <b-form class="form">
          <b-row>
            <b-col>
              <b-form-group label="XMLs" label-for="xmls">
                <b-form-file
                  autofocus
                  :file-name-formatter="formatXMLName"
                  placeholder="Selecione os arquivos"
                  ref="xmlFiles"
                  multiple
                  accept=".xml"
                  @change="setXMLFile"
                >
                </b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" ref="enviar_xmls" @click="importarXML">
          Importar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import XMLIcon from "vue-material-design-icons/FileDocument";
import Pencil from "vue-material-design-icons/Pencil.vue";
import swal from "sweetalert";
import FilterFields from "@/components/FilterFields";
import PrintIcon from "vue-material-design-icons/Printer";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import { actionsStore } from "@/components/Table/actionsStore";
import Menu from "vue-material-design-icons/Menu";

export default {
  name: "app-compras",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    XMLIcon,
    TrashCanIcon,
    FilterFields,
    PrintIcon,
    Pencil,
    Menu,
  },
  data() {
    return {
      actionsStore,
      components: {
        flatPickr,
      },
      fields: [
        {
          label: "N° NFe",
          field: "numeroNFe",
          type: "text",
          slot: true,
          sortable: false,
          width: "4%",
        },
        {
          label: "Modelo",
          field: "modeloNFe",
          type: "text",
          slot: true,
          sortable: false,
          width: "1%",
        },
        {
          label: "Fornecedor",
          field: "fornecedor",
          type: "text",
          slot: true,
          sortable: false,
          width: "25%",
        },
        {
          label: "Data Entrada",
          field: "dataEntrada",
          type: "data",
          filterable: true,
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Data Emissão",
          field: "dataEmissao",
          type: "data",
          filterable: true,
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Chave Acesso",
          field: "chaveDeAcesso",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "Valor Total",
          field: "valor",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "23%",
        },
      ],
      form: {},
      xmlFiles: [],
    };
  },
  methods: {
    async setXMLFile(event) {
      this.xmlFiles = await event.target.files;
    },
    formatXMLName(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} arquivos selecionados`;
    },
    async importarXML() {
      if (!this.xmlFiles) {
        return this.$toast.warning("Nenhum arquivo xml selecionado!");
      }
      if (this.xmlFiles.length > 20) {
        return this.$toast.warning("Importe 20 arquivos xml por vez!");
      }
      const submitButton = this.$refs["enviar_xmls"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.setAttribute("disabled", "");
      const formData = new FormData();

      for (let i = 0; i < this.xmlFiles.length; i++) {
        formData.append("xmls[" + i + "]", this.xmlFiles[i]);
      }

      this.$http
        .post("/compras/importar-xml-software", formData)
        .then(() => {
          this.$toast.success("NFe importadas com sucesso!");
          this.$bvModal.hide("modal-importar-xml");
          this.$refs.compras.refresh();
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.removeAttribute("disabled", "");
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.removeAttribute("disabled", "");
          this.$bvModal.hide("modal-importar-xml");
        });
    },
    formatFornecedor(fornecedor) {
      let forne = JSON.stringify(JSON.parse(fornecedor), null, 2);
      forne = JSON.parse(forne);
      return forne.nome;
    },
    async devolverNfe() {
      const id = this.actionsStore?.selectedRows[0]?.id;
      const numero = this.actionsStore?.selectedRows[0]?.numeroNFe;
      swal({
        title: "Devolver NFe?",
        text: `Deseja confirmar a devolução da NFe ${numero}, após confirmar verifique todos os dados e confirme se a natureza de operação esta de acordo com a devolução.`,
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/devolucao-compra", {
            id_nfe: id,
          })
          .then(({ data }) => {
            this.$router.push({
              name: "nfeNova",
              params: { nfeData: { ...data.dados }, fromDevolucao: true },
            });
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    editCompra(data) {
      swal({
        title: "Deseja editar a compra?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/compras/find", {
            id: data.id,
          })
          .then((result) => {
            this.$toast.info("Você será redirecionado agora");
            swal.stopLoading();
            swal.close();
            this.$router.push({
              name: "comprasNovo",
              params: { compraData: { ...result.data, compraId: data.id } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarlXml(data) {
      swal({
        title: "Deseja realizar a visualização do xml?",
        text: "Aguarde, pois será gerado o xml automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/compras/xml", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["xml-url"]) {
              window.open(result["data"]["xml-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Xml gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarPDF(data) {
      swal({
        title: "Deseja realizar a visualização da DANFE?",
        text: "Aguarde, pois será gerado o xml automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/compras/visualizar-pdf", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["url_pdf"]) {
              window.open(result["data"]["url_pdf"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("PDF gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        swal({
          title: "Deseja excluir a compra selecionada?",
          text: "Aguarde, pois será excluída também toda sua movimentação automaticamente!",
          icon: "info",
          buttons: {
            cancel: {
              text: "Cancelar",
              value: "cancel",
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "OK",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "cancel") {
            swal.close();
            swal.stopLoading();
            return;
          }
          await this.$http
            .post("/compras/delete", {
              id: data.id,
            })
            .then((result) => {
              if (result) {
                swal.stopLoading();
                swal.close();
                this.$toast.success("Compra excluída com sucesso!");
                this.$refs.compras.refresh();
              }
            })
            .catch(() => {
              swal.stopLoading();
              swal.close();
            });
        });
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
