import { required } from "vuelidate/lib/validators";

export default class ListaProdutoData {
  descricao = null;

  status = false;

  produtos = [];

  constructor({ descricao = null, status = false, produtos = [] }) {
    this.descricao = descricao;
    this.status = status;
    this.produtos = produtos;
  }
}

export const ListaProdutoDataRequired = {
  descricao: {
    required,
  },
};
