<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Número',
          field: 'numero',
        },
        {
          type: 'text',
          label: 'Chave de acesso',
          field: 'chave_acesso',
        },
        {
          type: 'text',
          label: 'Fornecedor',
          field: 'fornecedor',
        },
        {
          type: 'date',
          label: 'Data emissão',
          field: 'data_emissao',
        },
      ]"
    />
    <b-dropdown
      v-b-modal.menuAcoes
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      class="mb-2"
      style="position: absolute; top: 0; left: 50px"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item v-b-modal.monitorarModal>Monitorar NFes</b-dropdown-item>
      <b-dropdown-item v-b-modal.monitorarByChaveModal
        >Monitorar via chave de acesso</b-dropdown-item
      >
    </b-dropdown>

    <b-modal
      id="monitorarByChaveModal"
      title="Deseja monitorar via chave de acesso?"
      centered
      size="lg"
      hide-footer
    >
      <b-form class="form">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Informe a chave de acesso"
              label-for="chaveDeAcesso"
            >
              <b-form-input
                maxlength="44"
                minlength="44"
                v-model="chaveDeAcesso"
                autofocus
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-button
            variant="primary"
            @click="manitorarByChave"
            ref="monitorar_ByChave"
            class="mr-5"
          >
            Sim
          </b-button>
          <b-button @click="() => $bvModal.hide('monitorarByChaveModal')">
            Não
          </b-button>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="monitorarModal"
      title="Deseja consultar?"
      centered
      size="sm"
      hide-footer
    >
      <b-form class="form">
        <b-row align-h="center">
          <b-button
            variant="primary"
            @click="manifestar"
            ref="consultar_manifestos"
            class="mr-5"
          >
            Sim
          </b-button>
          <b-button @click="() => $bvModal.hide('monitorarModal')"> Não </b-button>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal id="manifestarNfe" title="Manifestar NFe" centered size="md">
      <b-form class="form">
        <b-row>
          <b-col>
            <b-form-group label="Status" label-for="status">
              <b-form-select
                v-model="statusToManifest"
                placeholder="Selecione o status a manifestar"
              >
                <b-form-select-option
                  v-for="status in allStatus"
                  :key="status.id + 'status'"
                  :value="status.id"
                >
                  {{ status.description }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Justificativa"
              label-for="manifestoJustificativa"
            >
              <v-textarea rows="3" v-model="manifestoJustify" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="primary"
          @click="manifestarNfe"
          ref="manifestar_nfe_button"
          class="mr-5"
        >
          Manifestar
        </b-button>
        <b-button
          @click="
            () => {
              $bvModal.hide('manifestarNfe');
              statusToManifest = null;
              manifestoDataSelected = null;
              manifestoJustify = '';
            }
          "
        >
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <TableApp
      titulo="Monitor NFe"
      api-url="/manifesto/lista"
      :fields="fields"
      ref="monitorNfe"
      :perPage="10"
      :multiselect="false"
    >
      <template slot="customRow" scope="props">
        <span slot="numero" v-if="props.field == 'numero'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  href="#"
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1font-size-lg"
                >
                  {{ props.rowData.numero }}
                </a>
              </div>
            </div>
          </span>
        </span>
        <span slot="fornecedor" v-if="props.field == 'fornecedor'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <span class="text-muted font-weight-bold d-block">{{
                props.rowData.fornecedor
              }}</span>
            </div>
          </span>
        </span>
        <span slot="data_emissao" v-if="props.field == 'data_emissao'">
          <div class="font-weight-bold text-muted">
            {{ formatDate(props.rowData.data_emissao) }}
          </div>
        </span>
        <span slot="chave_acesso" v-if="props.field == 'chave_acesso'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <span class="text-muted font-weight-bold d-block">{{
                props.rowData.chave_acesso
              }}</span>
            </div>
          </span>
        </span>
        <span slot="valor_total" v-if="props.field == 'valor_total'">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
            >R$
            {{ numberToReal(props.rowData.valor_total, 2) }}
          </span>
        </span>
        <span
          slot="status"
          v-if="props.field == 'status'"
          :class="statusMonitorClass(props.rowData.status)"
        >
          {{ statusMonitorLabel(props.rowData.status) }}
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <button
                  v-if="props.rowData.status === 7"
                  @click="selectManifestarNfe(props.rowData)"
                  class="btn btn-sm btn-success btn-icon mr-2"
                  v-b-tooltip.hover.right="'Manifestar'"
                >
                  <Check :size="16" />
                </button>
                <button
                  v-if="props.rowData.status === 4"
                  @click="downloadXML(props.rowData.id_manifesto)"
                  class="btn btn-sm btn-primary btn-icon mr-2"
                  v-b-tooltip.hover.right="'Download XML'"
                >
                  <Download :size="16" />
                </button>
                <button
                  v-if="props.rowData.status === 4"
                  @click="exportToCompra(props.rowData.id_manifesto)"
                  class="btn btn-sm btn-warning btn-icon mr-2"
                  v-b-tooltip.hover.right="'Exportar para compra'"
                >
                  <Import :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import Menu from "vue-material-design-icons/Menu";
import Download from "vue-material-design-icons/Download";
import Import from "vue-material-design-icons/Import";
import TableApp from "@/components/Table/Table2";
import Check from "vue-material-design-icons/CheckBold.vue";
import FilterFields from "@/components/FilterFields";
import { actionsStore } from "@/components/Table/actionsStore";
import swal from "sweetalert";

export default {
  name: "app-monitor",
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  components: {
    TableApp,
    Menu,
    Check,
    FilterFields,
    Download,
    Import,
  },
  data() {
    return {
      actionsStore,
      allStatus: [],
      manifestoDataSelected: null,
      statusToManifest: null,
      manifestoJustify: "",
      chaveDeAcesso: null,
      fields: [
        {
          label: "Número",
          field: "numero",
          slot: true,
          sortable: true,
          width: "5%",
        },
        {
          label: "Fornecedor",
          field: "fornecedor",
          width: "20%",
          type: "text",
          slot: true,
          sortable: true,
        },
        {
          label: "Chave de Acesso",
          field: "chave_acesso",
          type: "text",
          slot: true,
          sortable: false,
        },
        {
          label: "Data Emissão",
          field: "data_emissao",
          type: "date",
          filterable: true,
          slot: true,
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Total NFe",
          field: "valor_total",
          slot: true,
          type: "number",
          width: "10%",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "Ações",
          width: "10%",
          field: "actions",
          sortable: false,
          slot: true,
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getInit() {
      const { data } = await this.$http.post("/manifesto/lista-status");
      this.allStatus = data;
    },
    async exportToCompra(id_manifesto) {
      try {
        const { data } = await this.$http.post("/manifesto/download-xml", {
          id_manifesto,
        });
      } catch (error) {
        console.log(error);
      }

      this.$router.push({
        name: "comprasNovo",
        params: {
          id_manifesto,
        },
      });
    },
    async downloadXML(id_manifesto) {
      swal({
        title: "Deseja realizar a visualização do xml?",
        text: "Aguarde, pois será gerado o xml automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        try {
          const { data } = await this.$http.post("/manifesto/download-xml", {
            id_manifesto,
          });
          window.open(data["manifesto-xml-url"], "_blank");
        } catch (error) {
          console.log(error);
        }
        swal.stopLoading();
        swal.close();
      });
    },
    async manitorarByChave() {
      this.addLoadingToButton("monitorar_ByChave");

      if (!this.chaveDeAcesso) {
        this.removeLoadingToButton("monitorar_ByChave");

        this.$toast.warning("Informe a chave de acesso!");
        return;
      }

      try {
        await this.$http.post("/manifesto/inserir-chave", {
          chave: this.chaveDeAcesso,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$refs.monitorNfe.refresh();
        this.chaveDeAcesso = null;
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("monitorar_ByChave");
      this.$bvModal.hide("monitorarByChaveModal");
    },
    async manifestar() {
      this.addLoadingToButton("consultar_manifestos");
      try {
        await this.$http.post("/manifesto/consultar");
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$refs.monitorNfe.refresh();
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("consultar_manifestos");
      this.$bvModal.hide("monitorarModal");
    },
    async manifestarNfe() {
      try {
        if (!this.statusToManifest) {
          this.$toast.error("Selecione o status para manifestar!");
          return;
        }
        if (this.statusToManifest === 6 && this.manifestoJustify.length < 15) {
          this.$toast.error("Mínimo de 15 caracteres na justificativa!");
          return;
        }
        this.addLoadingToButton("manifestar_nfe_button");
        await this.$http.post("/manifesto/manifestar-nfe", {
          id_manifesto: this.manifestoDataSelected.id_manifesto,
          status: this.statusToManifest,
          ...(this.manifestoJustify && {
            jusitifcativa: this.manifestoJustify,
          }),
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Nota manifestada com sucesso!");
        this.$refs.monitorNfe.refresh();
      } catch (error) {
        console.log(error);
        this.removeLoadingToButton("manifestar_nfe_button");
      }
      this.$bvModal.hide("manifestarNfe");
      this.removeLoadingToButton("manifestar_nfe_button");
    },
    selectManifestarNfe(nfeManifestoData) {
      this.manifestoDataSelected = nfeManifestoData;
      this.$bvModal.show("manifestarNfe");
    },
  },
  mounted() {
    this.getInit();
  },
  created() {},
};
</script>
<style scoped></style>