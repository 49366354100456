/* eslint-disable prettier/prettier */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import { http } from "./configurations/axios";
import { VERIFY_ASSINATURA, VERIFY_AUTH } from "@/core/services/store/auth.module";
import VueToast from "vue-toast-notification";
import vueMoment from "vue-moment";
import VueSweetalert2 from "vue-sweetalert2";
import VueFormWizard from "vue-form-wizard";
import VueAnalytics from "vue-analytics";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueClipboard from "vue-clipboard2";
import { userAccessStore } from "./userAccessStore";
import { store as nfeStore } from "./modules/NFe/storeNfe";
import Echo from "laravel-echo";
import VueTimeline from "@growthbunker/vuetimeline";

window.Pusher = require("pusher-js");

Vue.use(VueTimeline);
window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  encrypted: true,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
});

const isProduction = process.env.VUE_APP_ENV === "PRODUCTION";

Vue.use(VueAnalytics, {
  id: "G-3ZKEGTRXQ0",
  router,
  debug: {
    enabled: false,
    sendHitTasK: isProduction,
  },
});

Vue.config.productionTip = false;

import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "vue-toast-notification/dist/theme-sugar.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/styles/App.scss";

const routesNotAuth = [
  "register",
  "login",
  "reset-password",
  "new-password",
  "orcamento/:uuid",
  "visualizarImpressao",
  "pedido/:uuid",
  "visualizarImpressaoBranco",
];

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

router.beforeEach(async (to, from, next) => {
  if (to.name !== "nfeNova") {
    nfeStore.dataFetched = false;
  }

  if (routesNotAuth.indexOf(to.name) == "-1") {
   Promise.all([store.dispatch(VERIFY_AUTH), store.dispatch(VERIFY_ASSINATURA)]).then(next)
    const splitName = to.name.split(/(?=[A-Z])/);
    const mainPath = to.name.split(/(?=[A-Z])/)[0];
    const mainPath2 = splitName.length > 1 && [splitName[0], splitName[1]].join("");
    const mainPath3 = splitName.length > 1 && splitName[1].toLowerCase();

    const kebabize = (str) => {
      return str
        .split("")
        .map((letter, idx) => {
          return letter.toUpperCase() === letter ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}` : letter;
        })
        .join("");
    };
    const mainPathKebab = kebabize(to.name);
    if (
      userAccessStore.access[mainPath] ||
      userAccessStore.access[mainPathKebab] ||
      to.name === "dashboard" ||
      to.name === "perfil" ||
      userAccessStore.access[to.name] ||
      userAccessStore.access[mainPath3] ||
      userAccessStore.access[mainPath2]
    ) {
      next();
    } else {
      next({ name: "dashboard" });
    }
  } else {
    next();
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.use(VueClipboard);

const DEFAULT_TITLE = "Nuts";
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

Vue.use(VueFormWizard);
Vue.prototype.$http = http;
Vue.use(VueSweetalert2);
Vue.use(vueMoment);
Vue.use(VueToast, {
  position: "top-right",
  duration: 8000,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
