<template>
  <Form url="/contrato-fatura/create" />
</template>

<script>
import Form from "./Form.vue";

export default {
  name: "app-contratos-create",
  data() {
    return {};
  },
  components: {
    Form,
  },
  mounted() {},
};
</script>
