import visualizacaoImpressao from "@/modules/VisualizacaoImpressao/Index.vue";

export default [
  {
    name: 'visualizarImpressao',
    path: "/visualizar-impressao",
    component: visualizacaoImpressao,
    meta: { title: "Impressão" },
  },
];
