<template>
  <div>
    <b-row class="px-4">
      <div
        class="card card-custom bg-light-warning card-stretch gutter-b p-4"
        v-if="certificadoVencidoMsg"
      >
        <p class="mb-0 font-weight-bolder">
          <Warning :size="20" />
          {{ certificadoVencidoMsg }}
          <strong
            ><a
              href="https://wa.me/554935500600?text=Quero saber mais sobre o certificado digital!"
              target="_blank"
            >
              Clique Aqui</a
            ></strong
          >
        </p>
      </div>
    </b-row>
    <b-row class="px-4 blinking-row">
      <div
        class="card card-custom bg-light-danger card-stretch gutter-b p-4"
        v-if="licenca.apresentar_renovacao"
      >
        <p class="mb-0 font-weight-bolder">
          <Warning :size="20" />
          Sua assinatura está prestes a vencer nos próximos dias, realize a sua
          renovação automaticamente:
          <strong>
            <a @click="$router.push({ name: 'assinatura' })">
              Para realizar a renovação agora, clique aqui!
            </a>
          </strong>
        </p>
      </div>
    </b-row>
    <div class="row">
      <div class="col-md-3">
        <div
          class="card card-custom bg-light-warning card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'produtosLista' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-success">
              <Estoque :size="16" />
            </span>
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
              >{{ totais.produtos }}</span
            >
            <span class="font-weight-bold font-size-sm">Produtos</span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div
          class="card card-custom bg-light-warning card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'pessoasLista' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-danger">
              <Pessoas :size="16" />
            </span>
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
              >{{ totais.pessoas }}</span
            >
            <span class="font-weight-bold font-size-sm">Pessoas</span>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="userAccessStore.access.financeiro">
        <div
          class="card card-custom bg-radial-gradient-success card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'contasReceber' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-success">
              <Financeiro :size="16" />
            </span>
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
              >R$ {{ numberToReal(totais.totalReceber, 2) }}</span
            >
            <span class="font-weight-bold font-size-sm"
              >Contas a receber do dia</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="userAccessStore.access.financeiro">
        <div
          class="card card-custom bg-radial-gradient-danger card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'contasPagarLista' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-warning">
              <Financeiro :size="16" />
            </span>
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
              >R$ {{ numberToReal(totais.totalPagar, 2) }}</span
            >
            <span class="font-weight-bold font-size-sm"
              >Contas a pagar do dia</span
            >
          </div>
        </div>
      </div>
      <div class="col-xl-6" v-if="userAccessStore.access.financeiro">
        <div
          style="cursor: pointer"
          :class="
            resumo.saldo > 0
              ? 'card card-custom bg-radial-gradient-success gutter-b'
              : 'card card-custom bg-radial-gradient-danger gutter-b'
          "
        >
          <div
            class="card-header border-0 py-5"
            v-b-toggle.accordion-2
            @click="() => (isSpanded = !isSpanded)"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
          >
            <h3 class="card-title font-weight-bolder text-white">
              Resumo Financeiro
            </h3>
            <span class="svg-icon svg-icon-2x" style="color: white">
              <Expand :size="16" v-if="!isSpanded" />
              <Minimize :size="16" v-else />
            </span>
          </div>
          <b-collapse
            id="accordion-2"
            :visible="true"
            accordion="my-accordion"
            role="tabpanel"
            class="p-0"
            style="position: relative"
          >
            <div
              class="bg-white card-rounded"
              @click="$router.push({ name: 'financeiroLista' })"
            >
              <div class="row m-0">
                <div class="col px-8 py-6 mr-8">
                  <div class="font-size-sm text-muted font-weight-bold">
                    Receitas
                  </div>
                  <div class="font-size-h5 font-weight-bolder text-success">
                    R$ {{ numberToReal(resumo.receita, 2) }}
                  </div>
                </div>
                <div class="col px-8 py-6">
                  <div class="font-size-sm text-muted font-weight-bold">
                    Despesas
                  </div>
                  <div class="font-size-h5 font-weight-bolder text-danger">
                    R$ -{{ numberToReal(resumo.despesa, 2) }}
                  </div>
                </div>
              </div>
              <div class="row m-0">
                <div class="col px-8 py-8 mr-8">
                  <div class="font-size-sm text-muted font-weight-bold">
                    Saldo atual
                  </div>
                  <div class="font-size-h1 font-weight-bolder">
                    R$ {{ numberToReal(resumo.saldo, 2) }}
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="col-md-2">
        <div
          class="card-custom bg-light-warning card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'linkProspect' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-warning">
              <ArrowRight :size="16" />
            </span>
            <span class="font-weight-bold font-size-lg">Atendimento</span>
          </div>
        </div>
        <div
          class="card-custom bg-light-warning card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'nfeNova' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-warning">
              <ArrowRight :size="16" />
            </span>
            <span class="font-weight-bold font-size-lg">NFe</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div
          class="card-custom bg-light-warning card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'vendasNovo' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-warning">
              <ArrowRight :size="16" />
            </span>
            <span class="font-weight-bold font-size-lg">NFCe</span>
          </div>
        </div>
        <div
          class="card-custom bg-light-warning card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'orcamentoNovo' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-warning">
              <ArrowRight :size="16" />
            </span>
            <span class="font-weight-bold font-size-lg">Orçamentos</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div
          class="card-custom bg-light-warning card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'pedidosNovo' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-warning">
              <ArrowRight :size="16" />
            </span>
            <span class="font-weight-bold font-size-lg">Pedidos</span>
          </div>
        </div>
        <div
          v-if="userAccessStore.access.nfse"
          class="card-custom bg-light-warning card-stretch gutter-b"
          style="cursor: pointer"
          @click="$router.push({ name: 'nova-nfseServicos' })"
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-warning">
              <ArrowRight :size="16" />
            </span>
            <span class="font-weight-bold font-size-lg">NFSe</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-xl-6">
          <div
            class="card card-custom bgi-no-repeat gutter-b card-stretch"
            style="
              background-position: right top;
              background-size: 40% auto;
              background-image: url(media/svg/shapes/abstract-3.svg);
            "
          >
            <div class="card-body">
              <a
                class="card-title font-weight-bold text-success text-hover-primary font-size-h5"
                >Aniversariantes do dia: {{ hoje }}</a
              >
              <div>
                <TableApp
                  item="aniversariante"
                  subTitulo=""
                  :data="aniversariantes"
                  :fields="fields"
                  classFilterColumn="nome"
                  ref="nome"
                >
                  <template slot="customRow" scope="props">
                    <span
                      slot="nome"
                      class="pl-0 pt-8"
                      v-if="props.field == 'nome'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold">
                          {{ props.rowData.nome }}
                        </div>
                      </div>
                    </span>
                    <span slot="actions" v-if="props.field == 'actions'">
                      <div
                        class="row-actions"
                        style="text-align: right !important"
                      >
                        <span
                          style="
                            overflow: visible;
                            position: relative;
                            width: 130px;
                          "
                        >
                          <div class="action-buttons">
                            <button
                              v-if="props.rowData.telefone"
                              @click="shareToWhatsapp(props.rowData)"
                              v-b-tooltip.righttop="
                                'Encaminhar os parabéns para o aniversariante'
                              "
                              class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                            >
                              <Whatsapp :size="20" />
                            </button>
                            <p v-else>Sem WhatsApp</p>
                          </div>
                        </span>
                      </div>
                    </span>
                  </template>
                </TableApp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SendToWppModal
      :data="dataToWhatsapp"
      :openModal="wppOpenModal"
      @handleOpenModal="(openModal) => (wppOpenModal = openModal)"
      module="aniversariante"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import Estoque from "vue-material-design-icons/Toolbox";
import Pessoas from "vue-material-design-icons/AccountGroup";
import Expand from "vue-material-design-icons/ChevronDown";
import Minimize from "vue-material-design-icons/ChevronUp";
import Financeiro from "vue-material-design-icons/Finance";
import ArrowRight from "vue-material-design-icons/ArrowRight";
import Warning from "vue-material-design-icons/AlertCircleOutline";
import "vue-loading-overlay/dist/vue-loading.css";
import { userAccessStore } from "@/userAccessStore";
import { priceOptions, quantityOptions } from "@/modules/Application";
import TableApp from "../../../components/Table/Table3.vue";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import SendToWppModal from "@/components/SendToWppModal";

export default {
  name: "app-dashboard",
  mixins: [SistemaMixin],
  components: {
    Estoque,
    Pessoas,
    Financeiro,
    Minimize,
    Expand,
    Warning,
    ArrowRight,
    TableApp,
    Whatsapp,
    SendToWppModal,
  },
  data() {
    return {
      userAccessStore,
      totais: [],
      isSpanded: false,
      priceOptions,
      quantityOptions,
      resumo: {},
      isLoading: false,
      isLoadingResumo: false,
      fullPage: false,
      reports: [],
      notificacao: [],
      certificadoVencidoMsg: "",
      hoje: null,
      aniversariantes: [],
      dataToWhatsapp: null,
      wppOpenModal: false,
      fields: [
        {
          label: "Nome",
          field: "nome",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "WhatsApp",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
    };
  },
  async mounted() {
    this.getDataInit();
    this.getResumo();
  },
  computed: {
    ...mapGetters(["licenca"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
  },
  methods: {
    onCancel() {
      return true;
    },
    async getDataInit() {
      console.log(this.licenca);
      this.hoje = this.thisDateDashboard();
      this.aniversariantes = await this.getAniversariantes();
      this.isLoading = true;
      this.totais = await this.getTotaisDashboard();
      this.isLoading = false;
      const response = await this.$http.post("/empresa/validade-certificado", {
        id: this.currentUser.usuario_empresa.id_empresa,
      });
      this.certificadoVencidoMsg = response?.data.mensagem ?? "";
    },
    async getResumo() {
      try {
        this.isLoadingResumo = true;
        const { data } = await this.$http.get("/financeiro/saldo");
        this.resumo = data;
        this.isLoadingResumo = false;
      } catch (e) {
        console.log(e);
      }
    },
    async shareToWhatsapp(data) {
      this.dataToWhatsapp = data;
      this.wppOpenModal = true;
    },
  },
};
</script>
<style scoped>
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blinking-row {
  animation: blink 1s infinite;
}
</style>
