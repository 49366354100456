import { required } from "vuelidate/lib/validators";

export default class PessoaData {
  nome = "";

  responsavel = "";

  status = "A";

  observacao = "";

  cnpj_cpf = "";

  ie_rg = "";

  inscricao_municipal = "";

  nome_fantasia = "";

  id_empresa = 1;

  id_lista_preco = null;

  id_pessoa_tipo = null;

  email = [];

  emails = [];

  endereco = [];

  enderecos = [];

  telefone = [];

  fones = [];

  id_pessoa = null;

  pessoa_tipo = null;

  veiculos = [];

  identificador_ie = null;

  produtor_rural = false;

  data_nascimento = null;

  naturalidade = null;

  profissao = null;

  estado_civil = null;

  conjuge = null;

  pai_mae = null;

  orgao_emissor_rg = null;

  created_at = null;

  tipo_atacado = false;

  representante = null;

  estrangeiro = false;

  logradouro_estrangeiro = null;

  numero_estrangeiro = null;

  bairro_estrangeiro = null;

  uf_estrangeiro = null;

  municipio_estrangeiro = null;

  pais_estrangeiro = null;

  limite_credito = 0;

  constructor({
    nome = "",
    responsavel = "",
    status = "A",
    observacao = "",
    cnpj_cpf = "",
    ie_rg = "",
    inscricao_municipal = "",
    nome_fantasia = "",
    id_empresa = 1,
    id_pessoa_tipo = null,
    email = [],
    endereco = [],
    telefone = [],
    emails = [],
    enderecos = [],
    fones = [],
    id_pessoa = null,
    pessoa_tipo = null,
    veiculos = [],
    identificador_ie = null,
    produtor_rural = false,
    data_nascimento = null,
    naturalidade = null,
    profissao = null,
    estado_civil = null,
    conjuge = null,
    pai_mae = null,
    orgao_emissor_rg = null,
    created_at = null,
    tipo_atacado = null,
    representante = null,
    estrangeiro = false,
    logradouro_estrangeiro = null,
    numero_estrangeiro = null,
    bairro_estrangeiro = null,
    uf_estrangeiro = null,
    municipio_estrangeiro = null,
    pais_estrangeiro = null,
    limite_credito = 0,
    id_lista_preco = null,
  }) {
    this.nome = nome;
    this.responsavel = responsavel;
    this.status = status;
    this.observacao = observacao;
    this.cnpj_cpf = cnpj_cpf;
    this.ie_rg = ie_rg;
    this.inscricao_municipal = inscricao_municipal;
    this.nome_fantasia = nome_fantasia;
    this.id_empresa = id_empresa;
    this.id_pessoa_tipo = id_pessoa_tipo;
    this.email = email;
    this.endereco = endereco;
    this.telefone = telefone;
    this.emails = emails;
    this.enderecos = enderecos;
    this.fones = fones;
    this.id_pessoa = id_pessoa;
    this.pessoa_tipo = pessoa_tipo;
    this.veiculos = veiculos;
    this.identificador_ie = identificador_ie;
    this.produtor_rural = produtor_rural;
    this.data_nascimento = data_nascimento;
    this.naturalidade = naturalidade;
    this.profissao = profissao;
    this.estado_civil = estado_civil;
    this.conjuge = conjuge;
    this.pai_mae = pai_mae;
    this.orgao_emissor_rg = orgao_emissor_rg;
    this.created_at = created_at;
    this.tipo_atacado = tipo_atacado;
    this.representante = representante;
    this.estrangeiro = estrangeiro;
    this.id_lista_preco = id_lista_preco;
    (this.logradouro_estrangeiro = logradouro_estrangeiro),
      (this.numero_estrangeiro = numero_estrangeiro),
      (this.bairro_estrangeiro = bairro_estrangeiro),
      (this.uf_estrangeiro = uf_estrangeiro),
      (this.municipio_estrangeiro = municipio_estrangeiro),
      (this.pais_estrangeiro = pais_estrangeiro),
      (this.limite_credito = limite_credito);
  }
}

export const PessoaDataRequired = {
  nome: {
    required,
  },
};
