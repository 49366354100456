<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Número',
          field: 'numero',
        },
        {
          type: 'text',
          label: 'Cliente',
          field: 'cliente',
        },
        {
          type: 'date',
          label: 'Data Emissão',
          field: 'range_emissao',
        },
        {
          type: 'date',
          label: 'Data Autorização',
          field: 'range_autorizacao',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: [
            'Todos',
            'Autorizada',
            'Cancelada',
            'Editando',
            'Processando',
            'Rejeitada',
          ],
        },
      ]"
    />
    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      v-if="actionsStore.selectedRows.length > 0"
      style="position: absolute; top: 0; left: 50px"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item
        v-b-modal.duplicarNFSe
        :disabled="
          actionsStore.selectedRows.length !== 1 ||
          actionsStore.selectedRows[0]?.status !== 3
        "
      >
        Duplicar NFSe
      </b-dropdown-item>
      <b-dropdown-item
        v-b-modal.transmitirNFSesModal
        :disabled="actionsStore.selectedRows[0]?.status === 3"
      >
        Transmitir NFSe
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      id="duplicarNFSe"
      title="Deseja duplicar a NFSe selecionada?"
      centered
      size="xs"
    >
      <label
        >NFSe a ser duplicada
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p></label
      >
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="duplicar"
            ref="doubleNFSe"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="transmitirNFSesModal"
      title="Deseja transmitir a(s) NFSe selecionada(s)?"
      centered
      size="xs"
    >
      <label
        >Após enviar as NFSe selecionadas, as mesmas serão precessadas e
        trasmitidas.</label
      >
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="transmitirNFSes"
            ref="transmitirNFSes"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="NFSe"
      acaoBtn="/#/servicos/nova-nfse"
      textoBtn="Nova NFSe"
      api-url="/nfse/paginate"
      :fields="fields"
      classFilterColumn="numero"
      ref="nfselist"
      :perPage="10"
      :multiselect="true"
    >
      <template slot="customRow" scope="props">
        <span slot="numero" class="pl-0 pt-8" v-if="props.field == 'numero'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.numero }}
              <p
                v-if="props.rowData.confirmacao_abertura_email"
                v-b-tooltip.right="'E-mail visualizado'"
              >
                <Mail :size="22" />
              </p>
            </div>
          </div>
        </span>

        <span slot="cliente" class="pl-0 pt-8" v-if="props.field == 'cliente'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.cliente }}
            </div>
          </div>
        </span>

        <span slot="emissao" class="pl-0 pt-8" v-if="props.field == 'emissao'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.emissao | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>

        <span
          slot="autorizacao"
          class="pl-0 pt-8"
          v-if="props.field == 'autorizacao'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.autorizacao | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>

        <span
          :id="`status-ref-${props.rowData.numero}`"
          slot="status"
          v-if="props.field == 'status'"
          :class="statusNFseClass(props.rowData.status)"
          v-b-tooltip.focus
        >
          {{ statusNFseLabel(props.rowData.status) }}
        </span>

        <b-tooltip
          :target="`status-ref-${props.rowData.numero}`"
          placement="left"
          v-if="props.rowData.rejeicao_tecnospeed != ''"
        >
          {{ props.rowData.rejeicao_tecnospeed }}
        </b-tooltip>

        <span
          slot="total_nfse"
          class="pl-0 pt-8"
          v-if="props.field == 'total_nfse'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ numberToReal(props.rowData.total_nfse, 2) }}
            </div>
          </div>
        </span>

        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible">
              <div class="action-buttons">
                <button
                  v-if="
                    (props.rowData.status === 1 ||
                      props.rowData.status === 5) &&
                    props.rowData.status !== 2
                  "
                  @click="handleEditNFse(props.rowData)"
                  v-b-tooltip.bottom="'Editar NFSe'"
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                >
                  <Pencil :size="16" />
                </button>
                <button
                  v-if="
                    (props.rowData.status === 1 ||
                      props.rowData.status === 5) &&
                    props.rowData.status !== 2
                  "
                  @click="handleDeleteNFse(props.rowData)"
                  v-b-tooltip.bottom="'Excluir NFSe'"
                  class="btn btn-sm btn-danger btn-icon mr-2"
                >
                  <TrashCanIcon :size="16" />
                </button>
                <button
                  v-if="
                    props.rowData.status === 3 && props.rowData.status !== 2
                  "
                  @click="handleCancelNFSe(props.rowData)"
                  v-b-tooltip.bottom="'Cancelar NFSe'"
                  class="btn btn-sm btn-danger btn-icon mr-2"
                >
                  <Cancel :size="20" />
                </button>
                <button
                  v-if="
                    props.rowData.status === 1 ||
                    props.rowData.status === 5 ||
                    props.rowData.status === 2
                  "
                  @click="handleConsultNFSe(props.rowData)"
                  v-b-tooltip.bottom="'Consultar NFSe'"
                  class="btn btn-sm btn-info btn-icon mr-2"
                >
                  <Magnify :size="22" />
                </button>
                <button
                  v-if="
                    props.rowData.status === 3 && props.rowData.status !== 2
                  "
                  @click="handleSendEmailNFSe(props.rowData)"
                  v-b-tooltip.bottom="'Enviar NFSe por email'"
                  class="btn btn-sm btn-success btn-icon mr-2"
                >
                  <Send :size="20" />
                </button>
                <button
                  @click="shareToWhatsapp(props.rowData)"
                  v-if="
                    props.rowData.status === 3 && props.rowData.status !== 2
                  "
                  v-b-tooltip.bottom="'Enviar NFSe por whatsapp'"
                  class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                >
                  <Whatsapp :size="20" />
                </button>
                <button
                  v-if="
                    props.rowData.status === 3 && props.rowData.status !== 2
                  "
                  @click="handleImprimirNFSe(props.rowData)"
                  v-b-tooltip.bottom="'Imprimir NFSe'"
                  class="btn btn-sm btn-success btn-icon mr-2"
                >
                  <PdfIcon :size="22" />
                </button>
                <button
                  v-if="
                    props.rowData.status === 3 && props.rowData.status !== 2
                  "
                  @click="gerarlXml(props.rowData)"
                  class="btn btn-sm btn-warning btn-hover-warning btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Gerar XML'"
                >
                  <XMLIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>

    <SendToWppModal
      :data="dataToWhatsapp"
      :openModal="wppOpenModal"
      @handleOpenModal="(openModal) => (wppOpenModal = openModal)"
      module="nfse"
    />
  </div>
</template>

<script>
import { SistemaMixin } from "@/modules/Application";
import TableApp from "@/components/Table/Table2";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import Magnify from "vue-material-design-icons/Magnify.vue";
import Send from "vue-material-design-icons/Send.vue";
import Cancel from "vue-material-design-icons/FileCancel.vue";
import swal from "sweetalert";
import { actionsStore } from "@/components/Table/actionsStore";
import PdfIcon from "vue-material-design-icons/FilePdfBox.vue";
import FilterFields from "@/components/FilterFields";
import Menu from "vue-material-design-icons/Menu";
import Mail from "vue-material-design-icons/Email";
import SendToWppModal from "@/components/SendToWppModal";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import XMLIcon from "vue-material-design-icons/FileDocument";

export default {
  name: "servicosNFse",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    TrashCanIcon,
    Pencil,
    Magnify,
    Send,
    Cancel,
    PdfIcon,
    FilterFields,
    Menu,
    SendToWppModal,
    Whatsapp,
    Mail,
    XMLIcon,
  },
  data() {
    return {
      actionsStore,
      dataToWhatsapp: null,
      fields: [
        {
          label: "Número",
          field: "numero",
          slot: true,
          sortable: false,
        },
        {
          label: "Cliente",
          field: "cliente",
          type: "text",
          slot: true,
          sortable: false,
        },
        {
          label: "Data de Emissao",
          field: "emissao",
          slot: true,
          sortable: false,
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          label: "Data de Autorização",
          field: "autorizacao",
          slot: true,
          sortable: false,
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          label: "Status",
          field: "status",
          slot: true,
          sortable: false,
        },
        {
          label: "Total",
          field: "total_nfse",
          slot: true,
          sortable: false,
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          width: "5%",
          slot: true,
        },
      ],
    };
  },
  created() {},
  methods: {
    async handleDeleteNFse({ id_nfse }) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir a NFSe " + id_nfse + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.$http.post("/nfse/delete", {
                id_nfse,
              });
              this.$toast.success("NFSe excluída com sucesso!");
              this.$refs.nfselist.refresh();
              this.actionsStore.updateTable = !this.actionsStore.updateTable;
            } catch (error) {
              console.log(error);
            }
          }
        });
    },
    shareToWhatsapp(data) {
      this.dataToWhatsapp = data;
      this.wppOpenModal = true;
    },
    async transmitirNFSes() {
      this.addLoadingToButton("transmitirNFSes");
      const nfseIds = this.actionsStore?.selectedRows.map((nfse) => ({
        id_nfse: nfse.id_nfse,
      }));

      if (nfseIds.length === 0) {
        this.$toast.error("NFSes sem ids válido!");
        this.removeLoadingToButton("transmitirNFSes");
        return;
      }
      try {
        await this.$http.post("/nfse/transmitir", {
          nfses: nfseIds,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("NFSe enviada com sucesso!");
        this.$refs.nfselist.refresh();
        this.$bvModal.hide("transmitirNFSesModal");
      } catch (error) {
        console.log(error);
      }

      this.removeLoadingToButton("transmitirNFSes");
    },
    async duplicar() {
      const id_nfse = this.actionsStore?.selectedRows[0]?.id_nfse;

      this.addLoadingToButton("doubleNFSe");
      if (!id_nfse) {
        this.$toast.error("NFSe sem id válido!");
        this.removeLoadingToButton("doubleNFSe");
        return;
      }
      try {
        await this.$http.post("/nfse/duplicar", {
          id_nfse,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("NFSe duplicada com sucesso!");
        this.$refs.nfselist.refresh();
        this.$bvModal.hide("duplicarNFSe");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("doubleNFSe");
    },
    async handleEditNFse({ id_nfse }) {
      swal({
        title: "Deseja editar a NFSe?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/nfse/get-by-id", {
            id_nfse,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "nova-nfseServicos",
              params: { nfseData: { ...response.data, id_nfse } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint da NFSe");
            swal.close();
          });
      });
    },
    async handleCancelNFSe(data) {
      swal({
        title: "Deseja cancelar a NFSe?",
        text: "Informe o motivo do cancelamento!",
        icon: "info",
        content: "input",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        if (value.length < 15) {
          swal.stopLoading();
          this.$toast.warning(
            "O motivo tem que possuir no mínimo 15 caracteres"
          );
          swal.stopLoading();
          swal.close();
          return;
        }

        await this.$http
          .post("/nfse/cancelar", {
            id_nfse: data.id_nfse,
            motivo: value,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success(
              "Solicitação de cancelamento enviada com sucesso!"
            );
            this.$refs.nfselist.refresh();
            this.actionsStore.updateTable = !this.actionsStore.updateTable;
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    handleImprimirNFSe({ id_nfse }) {
      swal({
        title: "Deseja gerar o pdf da NFSe?",
        text: "Aguarde, pois será gerado o pdf automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfse/imprimir", {
            id_nfse,
          })
          .then((result) => {
            const resultData = result["data"]["nfse_url"];
            if (resultData) {
              window.open(resultData, "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("PDF gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    async handleSendEmailNFSe(data) {
      swal({
        title: "Informe para quais e-mails deseja enviar a NFSe",
        text: "Separe diferentes email por vírgula",
        icon: "info",
        content: "input",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Enviar",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        if (value.length === 0) {
          swal.stopLoading();
          this.$toast.warning("informe ao menos 1 email!");
          return;
        }

        const emails = value.replaceAll(" ", "").split(",");

        await this.$http
          .post("/nfse/email", {
            id_nfse: data.id_nfse,
            destinatarios: emails,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("NFSe enviada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    async handleConsultNFSe({ id_nfse }) {
      swal({
        title: "Deseja consultar a NFSe?",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Sim",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        try {
          await this.$http.post("/nfse/consultar", {
            id_nfse,
          });
          this.$toast.success("NFSe consultada com sucesso!");
          swal.close();
          swal.stopLoading();
          this.actionsStore.updateTable = !this.actionsStore.updateTable;
        } catch (error) {
          swal.close();
          swal.stopLoading();
        }
      });
    },
    gerarlXml(data) {
      swal({
        title: "Deseja realizar a visualização do xml?",
        text: "Aguarde, pois será gerado o xml automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfse/xml", {
            id_nfse: data.id_nfse,
          })
          .then((result) => {
            if (result["data"]["xml_url"]) {
              window.open(result["data"]["xml_url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Xml gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
  },
  computed: {
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {},
};
</script>

<style>
.tooltip .tooltip-inner {
  /* box-shadow: none !important;
  -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 5px 0px !important;
}
</style>
