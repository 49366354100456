import contratosServicos from "@/modules/ContratosServicos/pages/Index";
import contratosServicosNovo from "@/modules/ContratosServicos/pages/Create";

export default [
  {
    name: "contratosServicos",
    path: "/contratosServicos",
    component: contratosServicos,
    meta: { title: "Contratos" },
  },
  {
    path: "/contrato-novo",
    name: "contratosServicosNovo",
    component: contratosServicosNovo,
    meta: { title: "Contratos" },
  },
];
