import ListaPreco from "@/modules/ListaPreco/pages/Index";
import ListaPrecoCreate from "@/modules/ListaPreco/pages/Create";
import ListaPrecoUpdate from "@/modules/ListaPreco/pages/Update";

export default [
  {
    path: "/lista",
    component: ListaPreco,
    name: "lista",
    meta: { title: "Lista de Preço" },
  },
  {
    path: "/lista/nova",
    component: ListaPrecoCreate,
    name: "listaPrecoCreate",
    meta: { title: "Nova Lista de Preço" },
  },
  {
    path: "/lista/:id_lista_preco/editar",
    component: ListaPrecoUpdate,
    name: "listaPrecoUpdate",
    meta: { title: "Editar Lista de Preço" },
  },
];
