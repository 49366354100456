<template>
  <b-tab
    title="Outras informações"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="w-75"
    @click="() => (store.currentTab = 4)"
  >
    <label>Validade</label>
    <b-row>
      <b-col sm="6">
        <b-form-group id="validadeData" label-for="validadeData" label="Data">
          <b-form-datepicker v-model="form.validade" locale="pt-br" />
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group id="validadeHora" label-for="validadeHora" label="Hora">
          <b-form-timepicker
            @input="setValidadeHour"
            v-model="form.validadeHour"
            locale="pt-br"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Status" label-for="status">
          <VueSelect
            ref="buscarStatus"
            :filterable="false"
            @input="setStatus"
            :options="allStatus"
            @search="onSearchStatus"
            v-model="form.status"
          >
            <template slot="no-options"> Informe o status </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="5">
        <b-form-group label="Pedido de Compra" label-for="pedido_compra">
          <b-form-input
            id="pedido_compra"
            name="pedido_compra"
            v-model="form.pedido_compra"
            maxlength="10"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="">
        <b-form-group
          label="Informações Adicionais"
          label-for="informacoesAdicionais"
        >
          <v-textarea
            full-width
            no-resize
            rows="1"
            auto-grow
            v-model="form.observacao"
          ></v-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storePedidos";
import InformacoesData from "../InformacoesData";

export default {
  name: "app-orcamento-form-informacoesTab",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {},
      allStatus: [
        {
          id: 1,
          descricao: "Editando",
        },
        {
          id: 3,
          descricao: "Autorizado",
        },
        {
          id: 4,
          descricao: "Cancelado",
        },
        {
          id: 9,
          descricao: "Produção",
        },
        {
          id: 10,
          descricao: "Entregue",
        },
        {
          id: 13,
          descricao: "Aprovado",
        },
        {
          id: 14,
          descricao: "Aguardando Aprovação",
        },
      ],
      status: null,
      keyToReRender: 0,
      store,
      statusError: false,
      enderecoError: false,
      statusFilled: false,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new InformacoesData({});
      this.form.status = this.allStatus.find((item) => item.id === 1);
      this.form.validade = this.thisDate();
      this.form.validadeHour = this.thisTime();

      if (Object.keys(this.$route.params).length !== 0) {
        const {
          dataValidade,
          observacao,
          status: statusId,
          pedido_compra,
        } = this.$route.params.pedidoData;
        this.form.observacao = observacao;
        this.form.validade = dataValidade;
        this.form.validadeHour = dataValidade.split(" ")[1];
        this.form.status = this.allStatus.find(
          (status) => status.id === statusId
        );
        this.form.pedido_compra = pedido_compra;
      }
    },
    onSearchStatus(search, loading) {
      if (search.length) {
        loading(true);
        this.searchStatus(loading, search, this);
      }
    },
    searchStatus: _.debounce(async (loading, search, vm) => {
      vm.allStatus = await vm.getClientes(search);
      loading(false);
    }, 350),
    setStatus(status) {
      this.form.status = status;
      this.statusFilled = true;
    },
    setValidadeHour(time) {
      this.form.validade = `${this.form.validade.split(" ")[0]} ${time}`;
      this.form.validadeHour = time;
    },
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 4;
    },
  },
  watch: {
    "store.formInformacoes"(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.keyToReRender++;
      }
    },
    form(newValue) {
      this.store.formInformacoes = newValue;
    },
  },
};
</script>
