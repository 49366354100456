<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="5">
                  <b-form-group id="nome" label-for="nome" label="Nome">
                    <b-form-input
                      v-model="form.nome"
                      :state="validateState('nome')"
                      autofocus
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="usuario" label-for="usuario" label="E-mail">
                    <b-form-input
                      v-model="$v.form.usuario.$model"
                      :state="validateState('usuario')"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="4">
                  <b-form-group label="Vendedor" label-for="vendedor">
                    <VueSelect
                      ref="buscaVendedor"
                      :filterable="false"
                      @input="setVendedor"
                      :options="vendedores"
                      v-model="form.vendedor"
                      @search="onSearchVendedor"
                    >
                      <template slot="no-options">
                        Informe o nome do vendedor
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="form-user-access">
                <label class="mb-5 mt-0"
                  >Selecione os recursos que o usuário poderá acessar</label
                >
                <b-col>
                  <div v-for="(r, index) in recursos" :key="'recurso' + index">
                    <b-row align-v="center">
                      <b-col md="auto">
                        <h3>
                          <b-button @click="collapseToggle(r.name)">+</b-button>
                        </h3>
                      </b-col>
                      <b-col>
                        <h3>{{ r.name }}</h3>
                      </b-col>
                      <b-col md="auto">
                        <span class="switch switch-icon">
                          <label v-b-tooltip.hover.right="'Negar/Permitir'">
                            <input
                              v-model="r.checked"
                              type="checkbox"
                              :value="true"
                              :checked="r.checked"
                              @change="checkMenu(r)"
                            />
                            <span></span>
                          </label>
                        </span>
                      </b-col>
                    </b-row>
                    <b-collapse :id="r.name" class="mb-5">
                      <div
                        v-for="(sub, key) in r.submenus"
                        :key="'sub' + key"
                        class="text-center ml-20 w-100"
                        align-v="center"
                      >
                        <div class="recursos-list">
                          <h7>{{ sub.name }}</h7>
                          <span
                            class="switch switch-icon"
                            v-b-tooltip.hover.right="'Negar/Permitir'"
                          >
                            <label>
                              <input
                                v-model="recursos[index].submenus[key].checked"
                                type="checkbox"
                                :value="true"
                                :checked="recursos[index].submenus[key].checked"
                              />
                              <span></span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="status" label-for="status" label="Ativo">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setAtivo"
                          :checked="form.status == 'A'"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_usuario_novo"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'usuariosLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import UsuariosData, { UsuariosDataRequired } from "./UsuariosData";
import { mapGetters } from "vuex";
import _ from "lodash";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "app-usuario-form",
  components: {
    VueSelect,
  },
  props: {
    url: {
      type: String,
    },
    usuario: {
      type: UsuariosData,
      required: true,
    },
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      recursos: [],
      vendedores: [],
    };
  },
  validations: {
    form: UsuariosDataRequired,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  watch: {
    usuario(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log(newValue);
        this.form = newValue;
        this.recursos = newValue.recursos;
      }
    },
  },
  methods: {
    setAtivo() {
      this.form.status = this.form.status == "A" ? "I" : "A";
    },
    checkMenu(menu) {
      menu.submenus = menu.submenus.map((submenu) => ({
        ...submenu,
        checked: menu.checked,
      }));
    },
    collapseToggle(id) {
      this.$root.$emit("bv::toggle::collapse", id);
    },
    async getDataInit() {
      this.form = new UsuariosData({});
      this.recursos = await this.getMenus();
      if (this.usuario.id_usuario) {
        this.form = await this.usuario;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_usuario_novo"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        if (this.recursos) {
          this.form.recursos = this.recursos;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "usuariosLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    onSearchVendedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchVendedor(loading, search, this);
      }
    },
    searchVendedor: _.debounce(async (loading, search, vm) => {
      vm.vendedores = await vm.getVendedorByNome(search);
      if (loading) loading(false);
    }, 350),
    setVendedor(vendedor) {
      this.form.id_vendedor = vendedor.id_vendedor;
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
.form-user-access {
  max-width: 400px;
  display: flex;
  margin: 30px auto;
  border: 2px solid #dedede;
  border-radius: 8px;
  padding: 1rem;
}
.recursos-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30%;
}
</style>
