import visualizacaoImpressaoBanco from "@/modules/VisualizacaoBanco/Index.vue";

export default [
  {
    name: 'visualizarImpressaoBranco',
    path: "/visualizar-impressao-banco",
    component: visualizacaoImpressaoBanco,
    meta: { title: "Movimentação Banco" },
  },
];
