<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Nova Lista de preço </span>
      </h3>
    </div>
    <Form url="/produtos/create-lista-preco" />
  </div>
</template>

<script>
import Form from "./Form.vue";

export default {
  name: "app-listaPreco-create",
  data() {
    return {
      lista: {},
    };
  },
  components: {
    Form,
  },
  computed: {},
  mounted() {},
};
</script>
