import { reactive } from "vue";

export const filtersStore = reactive({
  filterOptionSelected: {
    id: 1,
    label: "Hoje",
  },
  produtos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  contasReceber: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  contasPagar: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  pessoas: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  financeiro: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  orcamento: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  nfe: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  vendas: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  monitor: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  compras: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  vendedor: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  usuarios: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  contador: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  grupos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  unidades: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  revendas: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  links: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  bancos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  bancosMovimentacao: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  configuracoes: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  naturezas: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  tributacoes: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  objetos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  identificadores: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  tributacoesServicos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  servicos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  nfse: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  clientes: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  ordensServicos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  tecnicos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  veiculos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  motoristas: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  mdfe: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  prospect: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  kit: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  pedidos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  producao: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  variacoes: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  parcelamentos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  pos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  ordemCompra: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  lista: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  contratosServicos: {
    filters: {},
    per_page: 10,
    page: 1,
  },
  //TODO Add to other modules
});
