<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Código',
          field: 'codigo',
        },
        {
          type: 'text',
          label: 'Nome',
          field: 'nome',
        },
        {
          type: 'text',
          label: 'Fantasia',
          field: 'nome_fantasia',
        },
        {
          type: 'text',
          label: 'CPF/CNPJ',
          field: 'cnpj_cpf',
        },
        {
          type: 'date',
          label: 'Data Cadastro',
          field: 'created_at',
        },
        {
          type: 'text',
          label: 'Logradouro',
          field: 'logradouro',
        },
        {
          type: 'text',
          label: 'Bairro',
          field: 'bairro',
        },
        {
          type: 'text',
          label: 'Cidade',
          field: 'cidade',
        },
        {
          type: 'text',
          label: 'UF',
          field: 'uf',
        },
        {
          type: 'text',
          label: 'Profissão',
          field: 'profissao',
        },
        {
          type: 'text',
          label: 'Telefone',
          field: 'fone',
        },
        {
          type: 'text',
          label: 'Representante',
          field: 'representante',
        },
        {
          type: 'select',
          label: 'Tipo',
          field: 'tipo',
          options: ['Cliente', 'Fornecedor', 'Transportador', 'Todos'],
        },
      ]"
    />

    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      v-if="canApplyListaPreco"
      style="position: absolute; top: 0; left: 50px"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item @click="openListaDePrecoModal"
        >Aplicar Lista de preço
      </b-dropdown-item>
    </b-dropdown>

    <b-modal id="dadosClienteModal" title="Dados da pessoa:" centered size="xs">
      <label v-if="pessoaModal">
        Nome: {{ pessoaModal.nome }}
        <p v-if="pessoaModal.nome_fantasia">
          Fantasia: {{ pessoaModal.nome_fantasia }}
        </p>
        <p v-if="pessoaModal.responsavel">
          Contato: {{ pessoaModal.responsavel }}
        </p>
        <p v-if="pessoaModal.cnpj_cpf">
          CPF/CNPJ: {{ formatarCampo(pessoaModal.cnpj_cpf) }}
        </p>
        <p v-if="pessoaModal.ie_rg">IE/RG: {{ pessoaModal.ie_rg }}</p>
        <div v-if="pessoaModal.fones.length > 0">
          <label>Telefones: </label>
          <p v-for="(f, index) in pessoaModal.fones" :key="'code' + index">
            {{ f.fone }}
            <LinkPlus :size="20" @click="doCopy(f.fone)" />
          </p>
        </div>
        <div v-if="pessoaModal.emails.length > 0">
          <label>Emails: </label>
          <p v-for="(e, index) in pessoaModal.emails" :key="'code' + index">
            {{ e.email }}
            <LinkPlus :size="20" @click="doCopy(e.email)" />
          </p>
        </div>

        <div v-if="pessoaModal.enderecos.length > 0">
          <label>Endereços: </label>
          <p v-for="(e, index) in pessoaModal.enderecos" :key="'code' + index">
            {{ formatEnderecoModal(e) }}
          </p>
        </div>
        <div v-if="pessoaModal.limite_credito > 0">
          <label>Limite de crédito: </label>
          <p>R$ {{ numberToReal(pessoaModal.limite_credito) }}</p>
        </div>
        <div v-if="pessoaModal.conta_receber_vencida">
          <label class="label label-lg label-inline label-light-warning"
            >Cliente inadimplente</label
          >
        </div>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            @click="
              {
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="modalAnexo" title="Anexos" centered size="lg">
      <b-row>
        <b-col md="10">
          <b-form-group id="descricao" label="Descrição">
            <b-form-input
              v-model="descricao_anexo"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group id="anexo" label="Anexo">
            <b-form-file
              accept=".jpg, .png, .pdf"
              @change="(event) => setAnexo(event)"
              placeholder="Selecione o anexo.."
            ></b-form-file>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <button
            type="button"
            style="margin-top: 25px"
            @click="onSubmitAnexo"
            class="btn btn-light-primary btn-bold"
          >
            Adicionar
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Descrição</th>
                <th style="text-align: right">Anexo</th>
                <th style="text-align: right">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(a, index) in anexos" :key="'anexo_' + index">
                <td>{{ a.descricao }}</td>
                <td v-if="a.url && checkUrl(a.url)" style="text-align: right">
                  <a :href="a.url" target="_blank"> Visualizar</a>
                </td>
                <td v-else style="text-align: right">Arquivo não armazenado</td>
                <td align="right">
                  <button
                    @click="
                      () => {
                        $toast.error('Anexo excluído');
                        anexos.splice(index, 1);
                      }
                    "
                    class="btn btn-light-primary btn-sm btn-bold"
                  >
                    <TrashCan :size="16" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="salvarAnexos"
            ref="salvarAnexos"
            class="mr-5"
          >
            Salvar anexos
          </b-button>
          <b-button
            @click="
              {
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="Pessoas"
      subTitulo="Clientes / Fornecedores / Transportadoras"
      acaoBtn="/#/pessoas/nova"
      textoBtn="Novo"
      api-url="pessoa"
      :fields="fields"
      classFilterColumn="pessoa"
      ref="pessoa"
      :perPage="10"
      :multiselect="true"
    >
      <template slot="customRow" slot-scope="props">
        <span slot="codigo" v-if="props.field == 'codigo'">
          <span style="width: 110px">
            <div class="font-weight-bolder font-size-lg mb-0">
              {{ props.rowData.codigo }}
            </div>
          </span>
        </span>
        <span slot="nome" v-if="props.field == 'nome'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div
                class="symbol symbol-40 symbol-light-primary flex-shrink-0"
                style="margin-right: 10px"
              ></div>
              <div>
                <a
                  @click="dadosCliente(props.rowData)"
                  v-b-tooltip.hover.right="'Ver dados'"
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.nome }}</a
                >
                <span
                  class="text-muted font-weight-bold d-block"
                  v-for="tipoPessoa in props.rowData.pessoa_tipo"
                  :key="tipoPessoa.id_pessoa"
                  >{{ tipoPessoa.tipo.descricao }}</span
                >
              </div>
            </div>
          </span>
        </span>
        <span slot="nome_fantasia" v-if="props.field == 'nome_fantasia'">
          <span style="width: 110px">
            <div class="font-weight-bolder font-size-lg mb-0">
              {{ props.rowData.nome_fantasia }}
            </div>
          </span>
        </span>
        <span slot="fones" v-if="props.field == 'fones'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div
                style="font-size: 12px"
                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
              >
                {{ formatFones(props.rowData.fones) }}
              </div>
            </div>
          </span>
        </span>
        <span slot="enderecos" v-if="props.field == 'enderecos'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div
                style="font-size: 12px"
                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
              >
                {{ formatEndereco(props.rowData.enderecos) }}
              </div>
            </div>
          </span>
        </span>
        <span slot="cnpj_cpf" v-if="props.field == 'cnpj_cpf'">
          <span style="width: 110px" v-if="props.rowData.cnpj_cpf">
            <div
              class="font-weight-bolder font-size-lg mb-0"
              style="font-size: 12px"
            >
              {{ formatarCampo(props.rowData.cnpj_cpf) }}
            </div>
            <div
              class="font-weight-bold text-muted"
              v-if="props.rowData.ie_rg"
              style="font-size: 10px"
            >
              IE/RG: {{ props.rowData.ie_rg }}
            </div>
          </span>
        </span>
        <span slot="created_at" v-if="props.field == 'created_at'">
          <span style="width: 110px">
            <div class="font-weight-bolder font-size-lg mb-0">
              {{ formatDate(props.rowData.created_at) }}
            </div>
          </span>
        </span>
        <span slot="status" v-if="props.field == 'status'">
          <span
            v-if="props.rowData.status == 'A'"
            class="label label-lg label-inline label-light-success"
            >Ativo</span
          >
          <span v-else class="label label-lg label-inline label-light-danger"
            >Inativo</span
          >
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <button
                  size="sm"
                  @click="modalAnexos(props.rowData)"
                  class="btn btn-sm btn-primary btn-hover-primary btn-icon mr-2"
                  v-b-tooltip="'Anexos'"
                >
                  <Folder :size="16" />
                </button>
                <button
                  size="sm"
                  @click="gerarHistoricoCliente(props.rowData)"
                  class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                  v-b-tooltip="'Histórico'"
                >
                  <ChartBar :size="16" />
                </button>
                <router-link
                  size="sm"
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip="'Editar'"
                  :to="{
                    name: 'pessoasEditar',
                    params: {
                      id_pessoa: props.rowData.id_pessoa,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button
                  size="sm"
                  @click="excluir(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip="'Excluir'"
                >
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>

    <b-modal
      id="modal-apply-lista-preco"
      title="Aplicar lista de preço para os clientes"
      size="md"
      centered
    >
      <b-row>
        <b-col>
          <b-form-group label="Lista de preço" label-for="listaPreco">
            <VueSelect
              ref="listaPreco"
              :filterable="false"
              :options="listasPreco"
              @search="onSearchListaPreco"
              v-model="listaPrecoSelected"
            >
              <template slot="no-options"
                >Informe a lista de preço pela descrição</template
              >
              <template slot="option" slot-scope="option">
                <div class="d-flex justify-content-between">
                  <div>{{ option.descricao }}</div>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.descricao }}</div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row> </b-row>
      <div class="mx-auto">
        <b-row>
          <b-col>
            <div>
              <span class="font-weight-bold">Clientes: </span>
              <p v-for="client in actionsStore.selectedRows" :key="client.id">
                {{ client.nome }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button
          variant="primary"
          @click="aplicarListaPreco"
          ref="aplicar_lista_preco"
          >Aplicar lista preço</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import ChartBar from "vue-material-design-icons/ChartBar.vue";
import Folder from "vue-material-design-icons/Folder.vue";
import { onboardStore } from "../../../onboardStore";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import FilterFields from "@/components/FilterFields";
import swal from "sweetalert";
import LinkPlus from "vue-material-design-icons/LinkPlus.vue";
import TrashCan from "vue-material-design-icons/TrashCan.vue";
import Menu from "vue-material-design-icons/Menu";
import { actionsStore } from "@/components/Table/actionsStore";
import _ from "lodash";
import VueSelect from "vue-select";

export default {
  name: "app-pessoa",
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    FilterFields,
    ChartBar,
    LinkPlus,
    Folder,
    TrashCan,
    Menu,
    VueSelect,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      actionsStore,
      onboardStore,
      fields: [
        {
          label: "Código",
          field: "codigo",
          type: "text",
          slot: true,
          sortable: true,
          width: "2%",
        },
        {
          label: "Nome",
          field: "nome",
          type: "text",
          slot: true,
          sortable: true,
          width: "25%",
        },
        {
          label: "Fantasia",
          field: "nome_fantasia",
          type: "text",
          slot: true,
          sortable: true,
          width: "10%",
        },
        {
          label: "Telefones",
          field: "fones",
          type: "text",
          slot: true,
          sortable: true,
          width: "15%",
        },
        {
          label: "Endereço",
          field: "enderecos",
          type: "text",
          slot: true,
          sortable: true,
          width: "15%",
        },
        {
          label: "CPF/CNPJ",
          field: "cnpj_cpf",
          type: "text",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Data cadastro",
          field: "created_at",
          type: "date",
          slot: true,
          sortable: true,
          width: "2%",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
          width: "1%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "30%",
        },
      ],
      pessoaModal: null,
      descricao_anexo: null,
      anexo: null,
      anexos: [],
      pessoaAnexo: null,
      listasPreco: [],
      listaPrecoSelected: null,
    };
  },
  methods: {
    checkUrl(url) {
      if (typeof url === "string" || url instanceof String) {
        return true;
      }
      return false;
    },
    openListaDePrecoModal() {
      this.$bvModal.show("modal-apply-lista-preco");
    },
    async salvarAnexos() {
      if (!this.anexos) {
        this.removeLoadingToButton("salvarAnexos");
        return this.$toast.warning("Nenhum anexo inserido.");
      }
      this.addLoadingToButton("salvarAnexos");

      try {
        let formData = new FormData();

        for (var i = 0; i < this.anexos.length; i++) {
          let file = this.anexos[i];
          formData.append("arquivo[" + i + "]", file.url);
          formData.append("descricao[" + i + "]", file.descricao);
        }
        formData.append("id_pessoa", this.pessoaAnexo.id_pessoa);

        await this.$http.post("/pessoa/create-anexos", formData);

        this.removeLoadingToButton("salvarAnexos");

        this.pessoaAnexo = null;
        this.anexos = null;
        this.$bvModal.hide("modalAnexo");
        return this.$toast.success("Anexos gravados com sucesso.");
      } catch (error) {
        this.removeLoadingToButton("salvarAnexos");
        console.log(error);
      }
    },
    async setAnexo(event) {
      const data = await event.target.files[0];
      const url = URL.createObjectURL(data);
      this.anexo = data;
    },
    formatEnderecoModal(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${
        endereco.logradouro ?? ""
      }, ${endereco.numero ?? ""}, ${endereco.bairro ?? ""} - ${
        endereco?.cidade?.cidade ?? ""
      } / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
    dadosCliente(pessoa) {
      this.$bvModal.show("dadosClienteModal");
      this.pessoaModal = pessoa;
    },
    async modalAnexos(pessoa) {
      this.pessoaAnexo = pessoa;

      const { data } = await this.$http.post("/pessoa/get-anexos", {
        id_pessoa: pessoa.id_pessoa,
      });

      if (data) {
        this.anexos = data;
      }

      this.$bvModal.show("modalAnexo");
    },
    formatarCampo(campoTexto) {
      if (campoTexto.length <= 11) {
        return this.mascaraCpf(campoTexto);
      }
      return this.mascaraCnpj(campoTexto);
    },
    mascaraCpf(valor) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    },
    mascaraCnpj(valor) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o cliente " + data.nome + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/pessoa/delete", {
                id_pessoa: data.id_pessoa,
              });
              this.$refs.pessoa.refresh();
              this.$toast.success("Pessoa excluída!");
            }
          });
      });
    },
    gerarHistoricoCliente(pessoa) {
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/historico-cliente", {
            id: pessoa.id_pessoa,
            tela: true,
          })
          .then((result) => {
            if (result["data"]["url_report"]) {
              window.open(result["data"]["url_report"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Histórico gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    formatFones(fones) {
      var foneFormatado = "";
      fones.forEach((fone, key) => {
        foneFormatado += fone.fone;

        if (fones.length > 1) {
          foneFormatado += ", ";
        }
      });

      return foneFormatado;
    },
    doCopy(data) {
      window.navigator.clipboard.writeText(data);
      this.$toast.success("Conteúdo copiado com sucesso!");
    },
    onSubmitAnexo() {
      if (!this.anexo) {
        return this.$toast.warning("Selecione algum anexo!");
      }

      if (!this.descricao_anexo) {
        return this.$toast.warning("Informe a descrição do anexo!");
      }

      this.anexos.push({
        descricao: this.descricao_anexo,
        url: this.anexo,
      });

      this.descricao_anexo = null;
      this.anexo = null;
    },
    onSearchListaPreco(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchListaPreco(loading, search, this);
      }
    },
    searchListaPreco: _.debounce(async (loading, search, vm) => {
      vm.listasPreco = await vm.fetchListaPreco(search);
      loading(false);
    }, 350),
    async aplicarListaPreco() {
      const clientes = this.actionsStore.selectedRows;
      if (clientes.length === 0 || !this.listaPrecoSelected) return;
      const clientesIds = clientes.map((cliente) => ({
        id_pessoa: cliente.id_pessoa,
      }));
      this.addLoadingToButton("aplicar_lista_preco");
      try {
        await this.$http.post("/pessoa/update-lista-preco", {
          id_lista_preco: this.listaPrecoSelected.id_lista_preco,
          pessoas: clientesIds,
        });
        this.$toast.success("Lista de preço plicada aos clientes com sucesso!");
        this.$bvModal.hide("modal-apply-lista-preco");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("aplicar_lista_preco");
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    canApplyListaPreco() {
      return (
        this.actionsStore.selectedRows.length > 0 &&
        this.actionsStore.selectedRows.every((user) =>
          user.pessoa_tipo.some((tipo) => tipo.id_pessoa_tipo === 1)
        )
      );
    },
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    if (this.onboardStore.currentStep === 8) {
      this.$http.post("/usuario/onboarding", {
        onboarding: false,
        id_usuario: this.currentUser.id_usuario,
      });
      this.fireOnboardToast({
        id: "pessoasLista",
        title: "6/6 - Fim!",
        content: "Tudo pronto! Agora você pode realizar sua primeira venda!",
        buttonText: "Sim!",
        nextRouterStep: "dashboard",
        fowardStep: () => (this.onboardStore.currentStep = 0),
      });
    }
  },
};
</script>
