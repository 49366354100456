<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-body">
        <b-tabs class="hide-tabs">
          <b-tab active>
            <b-row>
              <b-col md="5">
                <b-row>
                  <b-col>
                    <b-form-group
                      id="descricao"
                      label-for="descricao"
                      label="Descrição"
                    >
                      <b-form-input
                        v-model="$v.form.descricao.$model"
                        :state="validateState('descricao')"
                        autofocus
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="2">
                <b-row>
                  <b-col>
                    <b-form-group id="status" label-for="status" label="Status">
                      <span class="switch switch-icon">
                        <label>
                          <input type="checkbox" v-model="form.status" />
                          <span></span>
                        </label>
                      </span>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row align-v="center">
              <b-col md="6">
                <b-form-group label="Produto" label-for="name">
                  <VueSelect
                    ref="buscarProduto"
                    :filterable="false"
                    @input="setProduto"
                    :options="produtosFetched"
                    @search="onSearchProduto"
                    v-model="produtoSelected"
                  >
                    <template slot="no-options">
                      Busque o produto pela descrição
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-flex justify-content-between">
                        <div>{{ option.codigo }} - {{ option.descricao }}</div>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }} - {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Preço lista R$" label-for="total">
                  <money
                    class="form-control"
                    id="total"
                    name="total"
                    v-model="produtoSelected.preco_lista"
                    v-bind="priceOptions"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="add">
                  <button @click="addProduto()" type="button">
                    <Plus
                      class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9"
                      v-if="windowWidth > 768"
                    />
                    <button
                      v-else
                      class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg ml-2"
                      type="button"
                    >
                      Adicionar produto
                    </button>
                  </button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <b-form-group label-for="add">
                  <button
                    @click="$bvModal.show('modal-busca-grupo')"
                    type="button"
                  >
                    <button
                      class="btn btn-primary btn-hover-dark font-weight-bolder font-size-lg ml-2"
                      type="button"
                    >
                      Buscar por grupo
                    </button>
                  </button>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="add">
                  <button
                    @click="$bvModal.show('modal-aplicar-para-estoque')"
                    type="button"
                  >
                    <button
                      class="btn btn-primary btn-hover-dark font-weight-bolder font-size-lg ml-2"
                      type="button"
                    >
                      Aplicar para todo estoque
                    </button>
                  </button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <TableApp
                  item="produto"
                  subTitulo=""
                  :data="produtos"
                  :fields="fields"
                  classFilterColumn="nome"
                  ref="nome"
                  :key="keyToRender"
                >
                  <template slot="customRow" scope="props">
                    <span
                      slot="item"
                      class="pl-0 pt-8"
                      v-if="props.field == 'item'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold text-muted">
                          {{ props.rowData.originalIndex + 1 }} /
                          {{ props.rowData.codigo }}
                        </div>
                      </div>
                    </span>
                    <span
                      slot="nome"
                      class="pl-0 pt-8"
                      v-if="props.field == 'descricao'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold text-muted">
                          {{ props.rowData.descricao }}
                        </div>
                      </div>
                    </span>
                    <span
                      slot="preco_lista"
                      v-if="props.field == 'preco_lista'"
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      R$
                      {{ numberToReal(props.rowData.preco_lista, "preco") }}
                    </span>

                    <span slot="actions" v-if="props.field == 'actions'">
                      <div
                        class="row-actions"
                        style="text-align: right !important"
                      >
                        <span
                          style="
                            overflow: visible;
                            position: relative;
                            width: 130px;
                          "
                        >
                          <div class="action-buttons">
                            <button
                              @click="
                                handleDeleteProduto(props.rowData.originalIndex)
                              "
                              class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                              type="button"
                            >
                              <TrashCanIcon :size="16" />
                            </button>
                          </div>
                        </span>
                      </div>
                    </span>
                  </template>
                </TableApp>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              type="submit"
              ref="submit_nova_lista"
              class="btn btn-primary font-weight-bold"
              @click="salvarLista"
            >
              Salvar
            </button>
            <router-link
              class="btn btn-clean font-weight-bold"
              :to="{
                name: 'lista',
              }"
            >
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-busca-grupo"
      title="Buscar por grupo de produto"
      size="lg"
      centered
    >
      <b-row>
        <b-col>
          <b-form-group label="Grupo de produtos" label-for="grupoValue">
            <VueSelect
              ref="buscarGrupoProduto"
              :filterable="false"
              :options="grupos"
              @search="onSearchGrupo"
              v-model="grupoSelected"
            >
              <template slot="no-options">
                Informe o grupo pelo descrição
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-flex justify-content-between">
                  <div>{{ option.nome }}</div>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.nome }}</div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row> </b-row>
      <div class="mx-auto">
        <b-row>
          <b-col>
            <b-form-group label="Percentual %" label-for="grupoValue">
              <money
                class="form-control"
                id="grupoValue"
                name="grupoValue"
                v-model="grupoOptions.value"
                v-bind="priceOptions"
              >
              </money>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Fator" label-for="fator">
              <select
                name="selectFator"
                id="selectFator"
                v-model="grupoOptions.fator"
                class="form-control"
                :class="{
                  'form-control': true,
                }"
              >
                <option value="acrescimo">Acrescimo</option>
                <option value="desconto">Desconto</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label-for="apply">
              <button
                class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg ml-2 mt-8"
                type="button"
                ref="applyPrecoGrupo"
                @click="aplicarPrecoGrupo"
              >
                Aplicar
              </button>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <TableApp
              item="produto"
              subTitulo=""
              :data="produtosGrupo"
              :fields="grupoFields"
              classFilterColumn="grupoProdutos"
              ref="grupoProdutos"
            >
              <template slot="customRow" scope="props">
                <span
                  slot="codigo"
                  class="pl-0 pt-8"
                  v-if="props.field == 'id'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ props.rowData.codigo }}
                    </div>
                  </div>
                </span>

                <span
                  slot="descricao"
                  class="pl-0 pt-8"
                  v-if="props.field == 'descricao'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ props.rowData.descricao }}
                    </div>
                  </div>
                </span>

                <span
                  slot="preco"
                  v-if="props.field == 'preco'"
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  R$
                  {{ numberToReal(props.rowData.preco, "preco") }}
                </span>
                <span
                  slot="preco_lista"
                  v-if="props.field == 'preco_lista'"
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  R$
                  {{ numberToReal(props.rowData.preco_lista, "preco") }}
                </span>
              </template>
            </TableApp>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button
          variant="primary"
          @click="salvarProdutosGrupo"
          ref="save_grupo"
        >
          Confirmar
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-aplicar-para-estoque"
      title="Aplicar lista para todo estoque?"
      size="md"
      centered
    >
      <b-row>
        <b-col>
          <b-form-group label="Percentual %" label-for="grupoValue">
            <money
              class="form-control"
              id="grupoValue"
              name="grupoValue"
              v-model="grupoOptions.value"
              v-bind="priceOptions"
            >
            </money>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Fator" label-for="fator">
            <select
              name="selectFator"
              id="selectFator"
              v-model="grupoOptions.fator"
              class="form-control"
              :class="{
                'form-control': true,
              }"
            >
              <option value="acrescimo">Acrescimo</option>
              <option value="desconto">Desconto</option>
            </select>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="confirmCancel('lista')"> Cancelar </b-button>
        <b-button
          variant="primary"
          @click="aplicarPrecoParaEstoque"
          ref="aplicarPrecoParaEstoque"
          >Aplicar</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import ListaProdutoData, { ListaProdutoDataRequired } from "./ListaProdutoData";
import _ from "lodash";
import VueSelect from "vue-select";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table3.vue";
import Plus from "vue-material-design-icons/PlusThick.vue";
import { Money } from "v-money";
import { priceOptions } from "@/modules/Application";
export default {
  name: "app-lista-produto-form",
  props: {
    url: {
      type: String,
    },
    lista: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueSelect,
    TableApp,
    TrashCanIcon,
    Plus,
    Money,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      priceOptions,
      keyToRender: 0,
      form: {},
      produtoSelected: {
        descricao: "",
        id_produto: null,
        preco_lista: 0,
      },
      produtosFetched: [],
      windowWidth: window.innerWidth,
      produtosGrupo: [],
      grupos: [],
      produtos: [],
      grupoSelected: null,
      grupoOptions: {
        descricao: "",
        value: 0,
        fator: "acrescimo",
      },
      fields: [
        {
          label: "Item/Cód",
          field: "item",
          slot: true,
          sortable: false,
          width: "5%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Nome",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Preço",
          field: "preco_lista",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
      grupoFields: [
        {
          label: "Cód",
          field: "id",
          slot: true,
          sortable: false,
          width: "5%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Nome",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "55%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Preço antigo",
          field: "preco",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "Preço",
          field: "preco_lista",
          sortable: false,
          slot: true,
          width: "20%",
        },
      ],
    };
  },
  validations: {
    form: ListaProdutoDataRequired,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  async mounted() {
    this.$root.$emit("bv::show::popover", "ambienteTuto");
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    await this.getDataInit();
  },
  methods: {
    async getDataInit() {
      this.form = new ListaProdutoData({});
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    salvarProdutosGrupo() {
      this.produtos = _.cloneDeep(this.produtosGrupo);
      this.produtosGrupo = [];
      this.$bvModal.hide("modal-busca-grupo");
    },
    async aplicarPrecoParaEstoque() {
      const { value, fator } = this.grupoOptions;

      this.addLoadingToButton("aplicarPrecoParaEstoque");

      try {
        const { data } = await this.$http.post("/produtos/get-produtos", {
          percentual: value,
          fator,
        });

        this.produtos = data;
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("aplicarPrecoParaEstoque");
      (this.grupoOptions = {
        descricao: "",
        value: 0,
        fator: "acrescimo",
      }),
        this.$bvModal.hide("modal-aplicar-para-estoque");
    },
    async salvarLista() {
      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        if (this.produtos.length === 0) {
          this.$toast.error("Insira ao menos um produto na lista!");
          return;
        }

        this.addLoadingToButton("submit_nova_lista");

        await this.$http.post(this.url, {
          ...this.form,
          produtos: this.produtos.map((produto) => ({
            ...produto,
          })),
          ...(this?.lista?.id_lista_preco && {
            id_lista_preco: this.lista.id_lista_preco,
          }),
        });
        this.removeLoadingToButton("submit_nova_lista");
        this.$router.push({ name: "lista" });
      } catch (error) {
        this.removeLoadingToButton("submit_nova_lista");
      }
    },
    handleDeleteProduto(index) {
      const aux = _.cloneDeep(this.produtos);
      aux.splice(index, 1);
      this.produtos = [...aux];
    },
    setProduto(produto) {
      if (!produto) return;

      this.produtoSelected = {
        codigo: produto.codigo,
        id: produto.id,
        descricao: produto.descricao,
        preco_lista:
          this.produtoSelected.preco_lista > 0
            ? this.produtoSelected.preco_lista
            : parseFloat(produto.preco),
      };
    },
    onSearchProduto(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchProdutos(loading, search, this);
      }
    },
    searchProdutos: _.debounce(async (loading, search, vm) => {
      vm.produtosFetched = await vm.fetchProdutos(search, vm.typeFilter);
      loading(false);
    }, 350),
    onSearchGrupo(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchGrupo(loading, search, this);
      }
    },
    searchGrupo: _.debounce(async (loading, search, vm) => {
      vm.grupos = await vm.fetchGrupo(search);
      loading(false);
    }, 350),
    async aplicarPrecoGrupo() {
      if (this.grupoOptions.value === 0) {
        this.$toast.error("Informe um valor para aplicar ao grupo!");
        return;
      }
      if (!this.grupoSelected) {
        this.$toast.error("Informe um grupo!");
        return;
      }

      this.addLoadingToButton("applyPrecoGrupo");
      try {
        this.produtosGrupo = await this.fetchProdutosGrupo(
          this.grupoSelected.id,
          this.grupoOptions.value,
          this.grupoOptions.fator
        );
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("applyPrecoGrupo");
    },
    async addProduto() {
      if (_.isEmpty(this.produtoSelected)) return;
      this.produtos = [...this.produtos, { ...this.produtoSelected }];

      this.produtoSelected = {
        descricao: "",
        id_produto: null,
        preco_lista: 0,
      };

      this.keyToRender = this.keyToRender + 1;
    },
  },
  watch: {
    lista(value) {
      if (value) {
        if (this.lista.id_lista_preco) {
          const { status, descricao, produtos } = this.lista;
          this.form.status = status;
          this.form.descricao = descricao;
          this.produtos = produtos.map((produto) => ({
            ...produto,
            preco_lista: produto.pivot.preco_lista,
          }));
        }
      }
    },
  },
};
</script>
