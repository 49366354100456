<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Lista de preço
        </span>
      </h3>
    </div>
    <Form url="/produtos/update-lista-preco" :lista="lista" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import { mapActions } from "vuex";

export default {
  name: "app-listaPreco-update",
  data() {
    return {
      lista: {},
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getLista(id_lista_preco) {
      try {
        const response = await this.$http.post("/produtos/get-lista-preco", {
          id_lista_preco,
        });

        const data = response.data;

        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const id_lista_preco = this.$route.params.id_lista_preco;

        this.setLoading(true);

        const lista = await this.getLista(id_lista_preco);

        if (!lista.id_lista_preco || lista == null) {
          this.$router.push({ name: "listaPreco" });
          return this.$toast.error("Lista não encontrada!");
        }

        this.lista = lista;
        this.setLoading(false);
      } catch {
        return;
      }
    },
  },
  computed: {},
  mounted() {
    this.getInit();
  },
};
</script>
