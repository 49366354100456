var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('FilterFields',{attrs:{"fields":[
      {
        type: 'number',
        label: 'Código',
        field: 'id_lista_preco',
      },
      {
        type: 'text',
        label: 'Descrição',
        field: 'descricao',
      },
      {
        type: 'select',
        label: 'Status',
        field: 'status',
        options: ['Ativo', 'Inativo'],
      },
    ]}}),_c('b-modal',{attrs:{"id":"excluirProdutos","title":"Deseja excluir todos os produtos selecionados?","centered":"","size":"xs"},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ ok, cancel }){return [_c('div',[_c('b-button',{ref:"excluirProdutos",staticClass:"mr-5",attrs:{"variant":"primary"},on:{"click":_vm.excluirProduto}},[_vm._v(" Excluir ")]),_c('b-button',{on:{"click":function($event){{
              _vm.actionsStore.updateTable = !_vm.actionsStore.updateTable;
              _vm.actionsStore.selectedRows = [];
              cancel();
            }}}},[_vm._v(" Cancelar ")])],1)]}}])},[_c('div',{staticClass:"d-flex"},[_c('p',[_vm._v(" Somente será possível excluir um produto, se não houver nenhum tipo de movimentação para o produto. ")])])]),_c('TableApp',{ref:"listaPre",attrs:{"titulo":"Lista de Preços","acaoBtn":"/#/lista/nova","textoBtn":"Nova","api-url":"/produtos/lista-preco","fields":_vm.fields,"classFilterColumn":"descricao","perPage":10},scopedSlots:_vm._u([{key:"customRow",fn:function(props){return [(props.field == 'descricao')?_c('span',{attrs:{"slot":"descricao"},slot:"descricao"},[_c('span',{staticClass:"pl-0 pt-8"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('a',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"},[_vm._v(_vm._s(props.rowData.descricao.substr(0, 50)))])])])])]):_vm._e(),(props.field == 'status')?_c('span',{attrs:{"slot":"status"},slot:"status"},[(props.rowData.status == true)?_c('span',{staticClass:"label label-lg label-inline label-light-success"},[_vm._v("Ativo")]):_c('span',{staticClass:"label label-lg label-inline label-light-danger"},[_vm._v("Inativo")])]):_vm._e(),(props.field == 'actions')?_c('span',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"row-actions",staticStyle:{"text-align":"right !important"}},[_c('span',{staticStyle:{"overflow":"visible","position":"relative","width":"130px"}},[_c('div',{staticClass:"dropdown dropdown-inline"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-info btn-hover-info btn-icon mr-2",attrs:{"to":{
                  name: 'listaPrecoUpdate',
                  params: {
                    id_lista_preco: props.rowData.id_lista_preco,
                  },
                }}},[_c('Pencil',{attrs:{"size":16,"title":"Editar"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Excluir'),expression:"'Excluir'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-danger btn-hover-danger btn-icon mr-2",on:{"click":function($event){return _vm.excluir(props.rowData)}}},[_c('TrashCanIcon',{attrs:{"size":16}})],1)],1)])])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }