<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'number',
          label: 'Código',
          field: 'id_lista_preco',
        },
        {
          type: 'text',
          label: 'Descrição',
          field: 'descricao',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: ['Ativo', 'Inativo'],
        },
      ]"
    />

    <b-modal
      id="excluirProdutos"
      title="Deseja excluir todos os produtos selecionados?"
      centered
      size="xs"
    >
      <div class="d-flex">
        <p>
          Somente será possível excluir um produto, se não houver nenhum tipo de
          movimentação para o produto.
        </p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="excluirProduto"
            ref="excluirProdutos"
            class="mr-5"
          >
            Excluir
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="Lista de Preços"
      acaoBtn="/#/lista/nova"
      textoBtn="Nova"
      api-url="/produtos/lista-preco"
      :fields="fields"
      classFilterColumn="descricao"
      ref="listaPre"
      :perPage="10"
    >
      <template slot="customRow" scope="props">
        <span slot="descricao" v-if="props.field == 'descricao'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.descricao.substr(0, 50) }}</a
                >
              </div>
            </div>
          </span>
        </span>

        <span slot="status" v-if="props.field == 'status'">
          <span
            v-if="props.rowData.status == true"
            class="label label-lg label-inline label-light-success"
            >Ativo</span
          >
          <span v-else class="label label-lg label-inline label-light-danger"
            >Inativo</span
          >
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Editar'"
                  :to="{
                    name: 'listaPrecoUpdate',
                    params: {
                      id_lista_preco: props.rowData.id_lista_preco,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button
                  @click="excluir(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Excluir'"
                >
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import FilterFields from "@/components/FilterFields";
import { actionsStore } from "@/components/Table/actionsStore";
import _ from "lodash";

export default {
  name: "app-lista-preco",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    FilterFields,
  },
  data() {
    return {
      actionsStore,
      fields: [
        {
          index: 1,
          label: "Descrição",
          field: "descricao",
          type: "text",
          slot: true,
          sortable: true,
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar referência",
          },
        },
        {
          index: 2,
          label: "Status",
          field: "status",
          width: "2%",
          sortable: false,
          slot: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          index: 3,
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir a lista " + data.descricao + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/produtos/delete-lista-preco", {
              id_lista_preco: data.id_lista_preco,
            });
            this.$refs.listaPre.refresh();
            this.$toast.success("Lista de preço excluída!");
          }
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked", "currentUser"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
