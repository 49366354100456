<template>
  <b-tab
    title="Cliente"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab"
    class="mt-5"
  >
    <b-row>
      <b-col>
        <b-form-group label="Nome" label-for="nome">
          <VueSelect
            ref="buscarCliente"
            :filterable="false"
            @input="setCliente"
            :class="{ hasErrors: clienteError, inputFilled: clienteFilled }"
            :options="clients"
            @search="onSearchCliente"
            v-model="store.formCliente"
          >
            <template slot="no-options">Informe o nome do cliente</template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>
                  {{ option.nome }}
                </div>
                <span class="text-muted"
                  >CPF/CNPJ: {{ formatCpfCnpj(option.cnpj_cpf) }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
          <b-form-input
            :key="keyToReRender"
            disabled
            id="cnpj_cpf"
            name="cnpj_cpf"
            v-model="store.formCliente.cnpj_cpf"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="8">
        <b-form-group
          :key="keyToReRender"
          label="Endereço"
          label-for="endereco"
        >
          <VueSelect
            ref="endereco"
            :filterable="false"
            @input="setEndereco"
            :options="enderecos"
            :class="{ hasErrors: enderecoError, inputFilled: enderecoFilled }"
            @search="onSearchEndereco"
            :value="valueAdress"
          >
            <template slot="no-options"> Informe o Endereço </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{
                  `${option.cep ?? ""}, ${option.logradouro ?? ""}, ${
                    option.numero ?? ""
                  }, ${option.bairro ?? ""} - ${
                    option?.cidade?.cidade ?? ""
                  } / ${option?.cidade?.uf ?? ""}`
                }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import {
  SistemaMixin,
  ValidacaoMixin,
  PessoaMixin,
} from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeContratos";
import { ClienteDataRequired } from "../ClienteData";

export default {
  name: "app-contrato-form-cliente",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      emissao: null,
      firstLoad: false,
      clientSelected: {
        cnpj_cpf: null,
      },
      clients: [],
      clienteFilled: false,
      clienteError: false,
      enderecoError: false,
      enderecoFilled: false,
      keyToReRender: 0,
      enderecos: [],
      realizar_reducao: false,
      calcular_substituicao: false,
      nomeCliente: null,
      cpfCnpjCliente: null,
      cidades: [],
      formEndereco: {},
    };
  },
  validations: {
    form: ClienteDataRequired,
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin, PessoaMixin],
  methods: {
    ...mapActions(["setLoading"]),
    onSearchEmail() {},
    onSearchCliente(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clients = await vm.getClientes(search);
      loading(false);
    }, 350),
    setCliente(cliente) {
      if (!cliente) return;
      this.store.formCliente = cliente;
      this.clienteFilled = true;
      this.enderecos = cliente.enderecos;
      this.store.formCliente.endereco = cliente.enderecos[0];
      this.keyToReRender++;
    },
    async getCliente(id_pessoa) {
      const pessoa = await this.getPessoa(id_pessoa);
      this.store.formCliente = {
        ...this.store.formCliente,
        ...pessoa,
      };
    },
    searchEndereco: _.debounce(async (loading, search, vm) => {
      vm.enderecos = vm.enderecos.filter((endereco) =>
        endereco.includes(search)
      );
      loading(false);
    }, 350),
    onSearchEndereco(search, loading) {
      if (search.length) {
        loading(true);
        this.searcEndereco(loading, search, this);
      }
    },
    setEndereco(endereco) {
      this.formCliente.endereco = endereco;
      this.keyToReRender++;
      this.enderecoFilled = true;
    },
    formatEndereco(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${
        endereco.logradouro ?? ""
      }, ${endereco.numero ?? ""}, ${endereco.bairro ?? ""} - ${
        endereco?.cidade?.cidade ?? ""
      } / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      const { tomador, data_emissao, endereco } = this.$route.params.nfseData;
      this.getCliente(tomador.id_pessoa);
      this.emissao = data_emissao;
      this.store.formCliente.endereco = endereco;
    } else {
      this.store.formCliente = {};
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 0;
    },
    valueAdress() {
      return this.store.formCliente?.endereco
        ? this.formatEndereco(this.store.formCliente.endereco)
        : null;
    },
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 1 && oldValue < newValue) {
        if (_.isEmpty(this.store.formCliente)) {
          this.$toast.error("Selecione o cliente!");
          this.store.currentTab = oldValue;
          return;
        }
        if (_.isEmpty(this.store.formCliente.endereco)) {
          this.$toast.error("Selecione um endereço!");
          this.store.currentTab = oldValue;
        }
      }
    },
  },
};
</script>
