<template>
  <div>
    <b-row class="px-4">
      <div
        class="card card-custom bg-light-warning card-stretch gutter-b p-4"
        v-if="contigenciaSVCAN"
      >
        <p class="mb-0 font-weight-bolder">
          <Warning :size="20" />
          Operações de Nota Fiscal Eletrônica sendo realizadas em ambiente de
          Contigência
        </p>
      </div>
    </b-row>
    <b-tab
      title="Natureza"
      :active="isTheCurrentTab"
      @click="() => (store.currentTab = 0)"
      :disabled="!isTheCurrentTab && !store.isUpdate"
      class="mt-5"
    >
      <b-row>
        <b-col sm="6">
          <b-form-group label="Natureza" label-for="natureza">
            <VueSelect
              id="naturezaWarning"
              ref="buscarNatureza"
              :filterable="false"
              @input="setNatureza"
              :options="naturezas"
              v-model="form.natureza"
              :class="{ hasErrors: naturezaError }"
              @search="onSearchNatureza"
              :state="validateState('natureza')"
            >
              <template slot="no-options">
                Informe o código da Natureza
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.descricao }} - {{ option.cfop.codigo }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.descricao }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="CFOP" label-for="cfop">
            <b-form-input disabled id="cfop" name="cfop" v-model="form.cfop">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group id="serie" label-for="serie" label="Série">
            <VueSelect
              ref="buscarserie"
              :filterable="false"
              v-model="form.serie"
              :options="series"
              @input="setSerie"
            >
              <template slot="no-options"> Informe a serie </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.serie }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.label }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <b-form-group
            id="finalidade"
            label-for="finalidade"
            label="Finalidade da NF-e"
          >
            <VueSelect
              ref="buscarFinalidade"
              :state="validateState('finalidade')"
              :filterable="false"
              :class="{ hasErrors: finalidadeError }"
              @input="setFinalidade"
              :options="finalidades"
              v-model="form.finalidade"
            >
              <template slot="no-options"> Informe a finalidade </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.descricao }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.descricao }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Vendedor" label-for="vendedor">
            <VueSelect
              ref="buscaVendedor"
              :filterable="false"
              @input="setVendedor"
              :options="vendedores"
              v-model="form.vendedor"
              @search="onSearchVendedor"
            >
              <template slot="no-options">
                Informe o nome do vendedor
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.nome }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Chave da NF-e"
            label-for="chave-nfe"
            v-if="isDevolution"
          >
            <b-form-input
              id="keyDevolution"
              name="keyDevolution"
              type="number"
              :formatter="formatKeyDevolution"
              v-model="form.keyDevolution"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <b-form-group
            id="dataEmissao"
            label-for="dataEmissao"
            label="Data Emissão"
          >
            <b-form-datepicker
              v-model="form.dataEmissao"
              :state="validateState('dataEmissao')"
              reset-button
              locale="pt-br"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group id="dataSaida" label-for="dataSaida" label="Data Saída">
            <b-form-datepicker
              v-model="form.dataSaida"
              reset-button
              locale="pt-br"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group id="horaSaida" label-for="horaSaida" label="Hora Saída">
            <b-form-timepicker
              v-model="form.horaSaida"
              locale="pt-br"
              reset-button
            >
            </b-form-timepicker>
          </b-form-group>
        </b-col>
      </b-row>
    </b-tab>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FormatMixin from "../../../Application/mixins/FormatMixin";
import NaturezaData, { NFeDataRequired } from "../NaturezaData";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import PagamentosMixin from "../../../Pdv/mixins/PagamentosMixin";
import { store } from "../../storeNfe";
import InstallmentsMixin from "../../../Application/mixins/InstallmentsMixin";
import swal from "sweetalert";
import EmitenteMixin from "../../../Application/mixins/EmitenteMixin";

export default {
  name: "app-nfe-form-naturezaTab",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {},
      naturezas: [],
      series: [],
      localeConfig: {
        placeholder: "Selecione uma data",
        today: "Hoje",
        reset: "Limpar",
        cancel: "Cancelar",
      },
      finalidades: [
        {
          codigo: 1,
          descricao: "Normal",
        },
        {
          codigo: 2,
          descricao: "Complementar",
        },
        {
          codigo: 3,
          descricao: "Ajuste",
        },
        {
          codigo: 4,
          descricao: "Devolução de mercadoria",
        },
      ],
      store,
      naturezaError: false,
      finalidadeError: false,
      vendedores: [],
      contigenciaSVCAN: false,
    };
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
    EmitenteMixin,
  ],
  validations: {
    form: NFeDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    formatKeyDevolution(e) {
      return String(e).substring(0, 44);
    },
    async getDataInit() {
      this.store.currentTab = 0;
      const emitenteData = await this.getDadosEmitente();
      this.form = new NaturezaData({});
      this.form.finalidade = this.finalidades.find(
        (finalidade) => finalidade.codigo === 1
      );
      if (!emitenteData.nome_certificado) {
        swal({
          title:
            "Verificamos que você ainda não configurou o certificado digital.",
          text: "Cadastre todas as informações do emitente e importe o seu ceritifcado digital",
          icon: "info",
          buttons: {
            confirm: {
              text: "Ok",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "ok") {
            swal.close();
            swal.stopLoading();
            return;
          }
          swal.close();
          swal.stopLoading();
        });
        this.$router.push({ name: "emitente" });
      }

      const { data } = await this.$http.post("/configuracoes/current-config", {
        modelo: 55,
      });

      if (data.length <= 0) {
        swal({
          title:
            "Verificamos que você ainda não realizou a configuração do ambiente",
          text: "Você foi redirecionado para o módulo de configuração",
          icon: "info",
          buttons: {
            confirm: {
              text: "Ok",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "ok") {
            swal.close();
            swal.stopLoading();
            return;
          }
          swal.close();
          swal.stopLoading();
        });
        this.$router.push({ name: "configuracoesNovo" });
      }

      this.contigenciaSVCAN = data[0].contigenciaSVCAN;
      this.store.cfop = null;

      if (Object.keys(this.$route.params).length !== 0) {
        this.handleLoading(true);
        const {
          natureza,
          serie,
          generationDate,
          outputDateTime,
          finNFe,
          keyDevolution,
          vendedor,
        } = this.$route.params.nfeData;
        const [saidaDate, saidaTime] = outputDateTime
          ? outputDateTime.split(" ")
          : [null, null];

        this.form.dataEmissao = generationDate;
        this.form.dataSaida = saidaDate;
        this.form.horaSaida = saidaTime;
        this.form.serie = serie;
        if (!this.form.serie) {
          this.series = data;
          this.form.serie = this.series[0].serie;
        }
        this.form.finalidade = this.finalidades.find(
          (finalidade) => finalidade?.codigo == finNFe
        );
        this.form.keyDevolution = keyDevolution;
        this.form.vendedor = vendedor;

        if (!this.form.vendedor) {
          if (this.currentUser.vendedor) {
            this.vendedor = this.currentUser.vendedor;
            this.form.vendedor = this.currentUser.vendedor.id_vendedor;
          }
        }

        this.form.natureza = natureza;

        if (["5202", "2202"].includes(natureza.cfop.codigo))
          this.store.cfop = natureza.cfop;
        this.store.formNatureza.cfop = natureza.cfop.codigo;
        this.$toast.info("Calculando NFe...");
        this.dataSendToApi = this.formatDataToSendToApi(
          this.store.formNatureza,
          this.store.formProduto,
          this.store.formCliente,
          this.store.formFaturamento.pagamentos.length === 0
            ? null
            : this.store.formFaturamento,
          this.store.formImpostos
        );
        const { dados } = await this.getTotaisNfe(this.dataSendToApi);
        this.store.formImpostos = {
          ...this.store.formImpostos,
          ...dados.total,
        };

        this.$toast.success("Totais da NFe atualizados!");
        if (this.$route.params.fromDevolucao) {
          this.store.fromDevolucao = true;
        }
        this.handleLoading(false);
      } else {
        this.series = data;
        this.form.serie = this.series[0].serie;
        this.form.dataEmissao = this.thisDate();
        this.form.dataSaida = this.thisDate();
        this.form.horaSaida = this.thisHours();

        if (this.currentUser.vendedor) {
          this.form.vendedor = this.currentUser.vendedor;
        }
        this.handleLoading(false);
      }
    },
    handleLoading(value) {
      this.setLoading(value);
    },

    setSerie(serieData) {
      this.form.serie = serieData.serie;
    },
    cleanErrors() {
      this.naturezaError = false;
      this.finalidadeError = false;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSearchVendedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchVendedor(loading, search, this);
      }
    },
    searchVendedor: _.debounce(async (loading, search, vm) => {
      vm.vendedores = await vm.getVendedorByNome(search);
      if (loading) loading(false);
    }, 350),
    onSearchNatureza(search, loading) {
      if (search.length) {
        loading(true);
        this.searchNatureza(loading, search, this);
      }
    },
    searchNatureza: _.debounce(async (loading, search, vm) => {
      vm.naturezas = await vm.getNaturezaByDescricao(search);
      if (loading) loading(false);
    }, 350),
    setNatureza(natureza) {
      this.form.natureza = natureza;
      this.form.cfop = natureza.cfop.codigo;
      this.store.formFaturamento.informacoes_adicionais =
        this.store.formFaturamento.informacoes_adicionais +
        (natureza.informacoesComplementares ?? "");
      this.naturezaError = false;
    },
    setFinalidade(finalidade) {
      this.form.finalidade = finalidade;
      this.finalidadeError = false;
    },
    setVendedor(vendedor) {
      this.form.vendedor = vendedor;
    },
  },
  mounted() {
    this.getDataInit();
  },
  watch: {
    form: {
      handler(newValue) {
        if (!this.store.cfop) {
          if (
            [
              "5202",
              "2202",
              "5915",
              "6915",
              "6152",
              "5152",
              "5901",
              "6901",
              "1901",
              "2901",
              "5916",
              "6916",
              "5949",
              "6949",
              "5912",
              "6912",
              "6202",
              "1202",
              "5411",
              "6411",
              "5101",
              "6101",
              "5403",
              "3102",
              "5925",
              "6925",
              "7102",
              "7101",
              "7949",
            ].includes(this.form.cfop)
          ) {
            swal({
              title: "Deseja aplicar o mesmo CFOP para os produtos desta NFe?",
              text: `Se sim todos os produtos cadastrados nesta NFe vão ter seu CFOP atribuido para ${this.form.cfop}`,
              icon: "info",
              buttons: {
                confirm: {
                  text: "Sim",
                  visible: true,
                  closeModal: false,
                },
                cancel: {
                  text: "Não",
                  visible: true,
                  closeModal: true,
                },
              },
              dangerMode: false,
              closeModal: false,
              closeOnClickOutside: false,
            }).then(async (value) => {
              if (value) {
                this.store.cfop = this.form.natureza.cfop;
                swal.close();
                swal.stopLoading();
                return;
              }
              swal.close();
              swal.stopLoading();
            });
          }
        }
        this.store.formNatureza = newValue;
      },
      deep: true,
    },
    finalidades(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      if (Object.keys(this.$route.params).length !== 0) return;
      this.form.finalidade = newValue[0];
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 1 && oldValue < newValue) {
        this.$v.form.$touch();

        if (
          this.isDevolution &&
          this.store.formNatureza.keyDevolution.length < 44
        ) {
          this.$toast.error("Chave da NFe tem que ter no mínimo 44 caracteres");
          this.store.currentTab = oldValue;
          return;
        }

        if (this.$v.form.$anyError) {
          if (this.$v.form.natureza.$anyError) {
            this.$toast.error("Preencha a natureza!");
            this.naturezaError = true;
          }
          if (this.$v.form.finalidade.$anyError) {
            this.$toast.error("Preencha a finalidade!");
            this.finalidadeError = true;
          }
          this.store.currentTab = oldValue;
          return;
        }
        this.store.dataFetched = true;
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    ...mapState({
      currentUser: (state) => state.auth.vendedor,
    }),
    isLoading() {
      return this.loading.loading;
    },
    isDevolution() {
      return (
        this.form?.finalidade?.descricao === "Devolução de mercadoria" ||
        this.form?.finalidade?.descricao === "Complementar" ||
        this.form?.finalidade?.descricao === "Ajuste"
      );
    },
    isTheCurrentTab() {
      return store.currentTab === 0;
    },
  },
};
</script>

<style>
.hasErrors .vs__dropdown-toggle {
  border: 2px solid red;
  /* color: #394066; */
}

.hasErrors .vs__clear,
.hasErrors .vs__open-indicator {
  fill: red;
}

.inputFilled .vs__dropdown-toggle {
  border: 2px solid #1bc5bd;
  /* color: #394066; */
}

.inputFilled .vs__clear,
.inputFilled .vs__open-indicator {
  fill: #1bc5bd;
}
</style>