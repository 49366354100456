<template>
  <div>
    <b-row v-if="idRevenda != 1">
      <div class="card-form">
        <div class="card-list">
          <div class="card-form-assinatura__inner">
            <div class="card-input" style="padding-top: 30px">
              <h3>Verifique sua assinatura com nosso Parceiro:</h3>
              <br />
              <h3 style="text-align: center">
                {{ dadosRevenda.nome_revenda }} <br />
                Telefone: {{ dadosRevenda.fone_revenda }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <b-row v-if="idRevenda == 1">
      <b-col md="12">
        <div class="card-form shadow">
          <div class="card-list">
            <div class="card-form-assinatura__inner text-center py-4 px-3">
              <h6 class="text-muted mb-3">
                Dúvidas? Entre em contato com nosso Comercial:
              </h6>
              <h2 class="text-primary mb-4">(49) 3550 0600</h2>
              <h2 class="mb-3">Assinatura:</h2>
              <h4>
                Sua licença expira no dia
                <strong>{{ formatDate(assinatura.data_validade) }}</strong
                >. Você está no plano <strong>{{ assinatura.plano }}</strong
                >.
              </h4>
              <h5 class="mt-4">
                Valor do plano:
                <strong>R$ {{ valorReal(assinatura.valor) }}</strong>
              </h5>

              <!-- Opção de Pagamento -->
              <div v-if="licenca.apresentar_renovacao">
                <div class="opcoes-pagamento mt-4">
                  <h5>Selecione a forma de pagamento para renovação:</h5>

                  <b-form-select v-model="opcaoSelecionada" placeholder="">
                    <b-form-select-option
                      v-for="opcao in opcoesPagamento"
                      :key="opcao.id"
                      :value="opcao.id"
                    >
                      {{ opcao.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </div>

                <!-- Botão Renovar com Parcelamento -->
                <b-button
                  variant="success"
                  target="_blank"
                  class="mt-4"
                  :disabled="isLoading"
                  :loading="isLoading"
                  @click="renovarPorMaisUmAno"
                >
                  Renovar com a Nuts
                </b-button>

                <!-- Observação sobre geração de boletos -->
                <p class="mt-2 text-muted small">
                  <br />
                  <strong
                    >Obs: Ao renovar, os boletos e NFSe serão gerados e enviados
                    automaticamente para o e-mail abaixo:
                  </strong>
                  <b-col md="10">
                    <b-form-input
                      ref="email"
                      id="email"
                      name="email"
                      v-model="licenca.email"
                    >
                    </b-form-input>
                  </b-col>
                </p>

                <!-- Link para Falar com a Nuts -->
                <div class="mt-3">
                  <strong>
                    <a
                      href="https://wa.me/554935500600?text=Quero renovar meu sistema!"
                      target="_blank"
                      class="contato-link"
                    >
                      Clique aqui para falar com a Nuts!
                    </a>
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col :md="colMDCartao">
        <CardForm
          v-show="chamaCartao"
          :form-data="formData"
          @input-card-number="updateCardNumber"
          @input-card-name="updateCardName"
          @input-card-month="updateCardMonth"
          @input-card-year="updateCardYear"
          @input-card-cvv="updateCardCvv"
          :plano="assinatura.plano"
          :valor="assinatura.valor"
          :id_plan="assinatura.id_plan"
          :vezesByPlan="vezesByPlan"
          :isSale="isSale"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin, FormatMixin } from "@/modules/Application";
import { mapGetters, mapActions } from "vuex";
import AssinaturaData, { AssinaturaDataRequired } from "./AssinaturaData";
import CardForm from "../Cards/CardForm";

export default {
  name: "app-assinatura",
  mixins: [validationMixin, SistemaMixin, FormatMixin],
  components: {
    CardForm,
  },
  data() {
    return {
      ocultaPlans: false,
      colMDSignature: 5,
      colMDCartao: 6,
      tabIndex: 0,
      assinatura: {},
      formData: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
      },
      cartao: {},
      chamaCartao: false,
      vezesByPlan: 1,
      essencialMonthly: "3",
      essencialYearly: "2",
      standardYearly: "4",
      planSelected: {},
      plans: [],
      isSale: false,
      opcaoSelecionada: null, // Armazena a opção selecionada pelo usuário
      opcoesPagamento: [
        { id: null, descricao: "Selecione uma opção" },
        { id: 1, descricao: "À vista Boleto" },
        { id: 2, descricao: "Parcelado Boleto - 15/30/60 dias" },
      ],
      isLoading: false,
    };
  },
  validations: {
    form: AssinaturaDataRequired,
  },
  watch: {
    revenda(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.revenda = newValue;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked", "idRevenda", "dadosRevenda", "licenca"]),
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    await this.getDataInit();
  },

  methods: {
    ...mapActions(["setLoading"]),
    // eslint-disable-next-line no-unused-vars
    updateCardNumber(val) {},
    // eslint-disable-next-line no-unused-vars
    updateCardName(val) {},
    // eslint-disable-next-line no-unused-vars
    updateCardMonth(val) {},
    // eslint-disable-next-line no-unused-vars
    updateCardYear(val) {},
    // eslint-disable-next-line no-unused-vars
    updateCardCvv(val) {},
    async getDataInit() {
      this.setLoading(true);
      const data = await this.getAssinaturaByClient();
      this.plans = await this.getPlans();

      if (!data.id_empresa || data == null) {
        return this.$toast.error("Assinatrura não encontrada!");
      }

      this.assinatura = new AssinaturaData(data);
      this.cartao.vezes = data.vezes ?? null;
      this.cartao.mes = data.mes ?? null;
      this.cartao.ano = data.ano ?? null;
      this.cartao.numero_mascara = data.numero_mascara ?? null;

      if (this.assinatura.id_plan == this.essencialMonthly) {
        this.vezesByPlan = 1;
      }

      if (this.assinatura.id_plan == this.essencialYearly) {
        this.vezesByPlan = 6;
      }

      this.setLoading(false);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    cadastrarCartao() {
      this.chamaCartao = true;
      this.colMDSignature = 6;
      this.ocultaPlans = true;
    },
    chamaPlans() {
      this.chamaCartao = false;
      this.ocultaPlans = false;
      this.planSelected = {};
      this.getDataInit();
    },
    async postPlan(plan, isSale) {
      this.setLoading(true);
      this.$toast.success("Plano Selecionado com sucesso!");

      if (plan.id_plan == this.essencialYearly) {
        this.vezesByPlan = 6;
      }

      if (plan.id_plan == this.essencialMonthly) {
        this.vezesByPlan = 1;
      }

      this.assinatura.id_plan = plan.id_plan;
      this.chamaCartao = true;
      this.ocultaPlans = true;
      this.planSelected = plan;
      this.isSale = isSale;
      this.setLoading(false);
    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
    },
    enter: function (el, done) {
      console.log(el);
      var delay = el.dataset.index * 150;
      setTimeout(function () {
        el.style.opacity = 1;
        setTimeout(() => done(), 500);
      }, delay);
    },
    leave: function (el, done) {
      var delay = el.dataset.index * 150;
      setTimeout(function () {
        /* 
          can use classes
          or could use something like velocity/animejs with callbacks
          for the done call
        */
        el.style.opacity = 0;
        setTimeout(() => done(), 500);
      }, delay);
    },
    async renovarPorMaisUmAno() {
      this.isLoading = true;
      this.setLoading(true);

      try {
        await this.$http.post("/contrato-fatura/assinatura", {
          id_empresa: this.assinatura.id_empresa,
          id_assinatura: this.assinatura.id_assinatura,
          id_plan: this.assinatura.id_plan,
          parcelamento: this.opcaoSelecionada,
          cnpj_cpf: this.assinatura.cnpj_cpf,
          email_fatura: this.licenca.email,
        });

        this.$toast.success(
          "Assinatura renovada com sucesso, irá receber por e-mail os boletos da cobrança!"
        );
        this.isLoading = false;
        this.setLoading(false);
        this.$router.push({ name: "dashboard" });
      } catch (error) {
        this.$toast.danger(
          "Houve algum problema na sua renovação, chame um consultor da Nuts!"
        );
        this.isLoading = false;
        this.setLoading(false);
      }
    },
  },
};
</script>

<style lang="scss">
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
@import "../../../assets/styles/styleCardPayment.scss";

$white: #fff;
$wild-sand: #f4f4f4;
$silver: #c9c9c9;
$contessa: black;
$astral: black;
$fountain-blue: #53bbb4;
$plan-colors: $contessa, $astral, $fountain-blue;
$radius: 3px;

.btn {
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: $radius;
  transition: all 250ms ease-in-out;
  &.is-fullwidth {
    width: 100%;
  }
}

.plan__table {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1024px;
  margin: 0 auto;
}

.plan {
  flex: 1;
  padding: 1.5rem 1rem 2rem 1rem;
  margin: 1rem;
  background: $white;
  border-radius: $radius;
  box-shadow: 0 8px 12px 0 rgba(122, 122, 122, 0.2);
  // border: 1px solid $silver;
  transition: opacity 500ms linear, transform 250ms ease-in-out;

  &:hover {
    transform: scale(1.05) translateZ(0);
    backface-visibility: hidden;
    // transform: translateZ(0);
  }
}

.plan__header {
  padding: 0.25rem 1rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: $white;
  border-radius: $radius;
}

@for $i from 1 through length($plan-colors) {
  .plan:nth-child(#{$i}) {
    > .plan__header {
      background-color: nth($plan-colors, $i);
    }

    // not sure I like this
    .plan__price {
      color: lighten(nth($plan-colors, $i), 25%);
    }

    .btn {
      background-color: nth($plan-colors, $i);

      &:hover {
        background-color: darken(nth($plan-colors, $i), 5%);
      }
    }
  }
}

.plan__price-box {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  > .plan__currency {
    align-self: center;
  }
  > .plan__price {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: 600;
  }
  > .plan__period {
    align-self: flex-end;
  }
}

.plan__features {
  padding-bottom: 1rem 0;
  padding: 1rem 0.5rem;
}

.plan__feature {
  position: relative;
  padding-left: 2rem;
}

.plan__feature::before {
  background-size: 2rem;
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 0;
  color: #307aab;
}

.plan__footer {
  padding-top: 1rem;
}

.text-white {
  color: $white;
}

@media only screen and (max-width: 768px) {
  .plan__table {
    flex-direction: column;
  }
}
.card-form {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.card-form-assinatura__inner {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.text-primary {
  color: #007bff;
}
.text-muted {
  color: #6c757d;
}
.destaque {
  font-size: 1.25rem;
  padding: 0.75rem 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
.contato-link {
  font-size: 0.9rem;
  text-decoration: underline;
  color: #007bff;
}
.contato-link:hover {
  text-decoration: none;
}
</style>
