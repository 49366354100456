import { required } from "vuelidate/lib/validators";

export default class InformacoesData {
  data_inicio = null;
  data_termino = null;
  status = null;
  observacao = "";
  email_fatura = "";
  emitir_nfse = true;
  emitir_boletos = false;
  constructor(
    data_inicio = null,
    data_termino = null,
    status = null,
    observacao = "",
    email_fatura = "",
    emitir_nfse = true,
    emitir_boletos = false
  ) {
    this.data_inicio = data_inicio;
    this.data_termino = data_termino;
    this.status = status;
    this.observacao = observacao;
    this.email_fatura = email_fatura;
    this.emitir_nfse = emitir_nfse;
    this.emitir_boletos = emitir_boletos;
  }
}

export const InformacoesDataRequired = {
  data_inicio: {
    required,
  },
  data_termino: {
    required,
  },
  status: {
    required,
  },
};
