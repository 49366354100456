import { required } from "vuelidate/lib/validators";

export default class UsuariosData {
  usuario = "";
  nome = "";
  status = "A";
  id_usuario = null;
  recursos = null;
  id_vendedor = null;
  vendedor = null;
  constructor({
    usuario = "",
    nome = "",
    status = "A",
    id_usuario = null,
    recursos = null,
    id_vendedor = null,
    vendedor = null,
  }) {
    this.usuario = usuario;
    this.nome = nome;
    this.status = status;
    this.id_usuario = id_usuario;
    this.recursos = recursos;
    this.id_vendedor = id_vendedor;
    this.vendedor = vendedor;
  }
}

export const UsuariosDataRequired = {
  usuario: {
    required,
  },
  nome: {
    required,
  },
};
