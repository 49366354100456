<template>
  <div class="container-fluid">
    <div class="card-body">
      <b-row align-h="end">
        <button
          v-if="osToImport.length"
          class="btn btn-primary font-weight-bold mr-2"
          style="height: 38px; margin-bottom: 10px"
          v-b-modal.importarOS
          ref="import-orcamento-button"
          v-b-tooltip.top="
            'Somente irá importar as OS: Autorizadas, Finalizadas, Em andamento e Serviço concluído'
          "
        >
          Importar OS
        </button>
      </b-row>
      <b-card>
        <b-tabs
          pills
          :horizontal="windowWidth < 768"
          :vertical="windowWidth > 768"
          :nav-wrapper-class="windowWidth > 768 ? 'w-25' : 'w-100'"
          fill
        >
          <Tomador />
          <Servicos />
          <Pagamento />
        </b-tabs>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <div v-if="canSaveNfse">
              <div class="form mb-5">
                <button
                  class="btn btn-secondary font-weight-bold mr-4"
                  @click="handlePrevious"
                >
                  Anterior
                </button>
                <button
                  ref="submit_nova_nfse"
                  class="btn btn-primary font-weight-bold"
                  @click="salvarNfse"
                >
                  Salvar
                </button>
                <button
                  v-b-modal.modalTransmitirNfse
                  class="btn btn-secondary font-weight-bold mx-2"
                >
                  Transmitir
                </button>
                <router-link
                  class="btn btn-clean font-weight-bold"
                  :to="{
                    name: 'nfseServicos',
                  }"
                >
                  Cancelar
                </router-link>
              </div>
            </div>
            <div v-else>
              <button
                :disabled="store.currentTab === 0"
                class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious"
              >
                Anterior
              </button>
              <button
                class="btn btn-primary font-weight-bold"
                @click="handleNext"
              >
                Proximo
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'nfseServicos',
                }"
                @click="
                  () => {
                    store.dataFetched = false;
                  }
                "
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modalTransmitirNfse" title="Transmissão" centered>
      <b-form class="form">
        <div class="d-flex flex-column mx-auto" style="gap: 0.5rem">
          <b-row>
            <b-col>
              <b-form-group
                id="emailToSendNfse"
                label-for="emailToSendNfse"
                label="Email para enviar a NFSe"
              >
                <b-form-input
                  v-model="emailToSendNfse"
                  autofocus
                  v-if="store.formTomador?.emails?.length === 0"
                />
                <b-form-select v-model="emailToSendNfse" placeholder="" v-else>
                  <b-form-select-option
                    v-for="email in store.formTomador?.emails"
                    :key="email.id_pessoa_email + 'plano_contas'"
                    :value="email.email"
                  >
                    {{ email.email }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <CheckBox
                v-b-tooltip="checkNutsDigital()"
                :disabled="!nutsDigital"
                label="Gerar boleto"
                v-model="generateBoleto"
              />
            </b-col>
          </b-row>
        </div>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="primary"
          @click="transmitirNFse"
          ref="transmitir_nfse"
        >
          Transmitir
        </b-button>
        <b-button @click="cancel()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal id="importarOS" title="Importar OS" centered>
      <b-form class="form">
        <b-row align-h="center">
          <b-col>
            <b-form-group :key="keyToReRender" label="OS" label-for="os">
              <VueSelect
                multiple
                ref="os"
                :filterable="false"
                :options="osToImport"
                v-model="selectedOS"
                @search="onSearchOS"
              >
                <template slot="no-options"> Informe a OS</template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="importarOS" ref="importar_os">
          Importar
        </b-button>
        <b-button @click="cancel()"> Cancelar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import "vue-select/dist/vue-select.css";
import FormatMixin from "../../Application/mixins/FormatMixin";
import { SistemaMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import Tomador from "./components/Tomador.vue";
import Servicos from "./components/Servicos.vue";
import Pagamento from "./components/Pagamento.vue";
import { store } from "../storeServicos";
import _ from "lodash";
import CheckBox from "@/components/Form/Checkbox.vue";
import VueSelect from "vue-select";

export default {
  name: "app-nfse-form",
  props: {
    nfse: {
      type: Object,
      default: () => {},
    },
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      windowWidth: window.innerWidth,
      generateBoleto: false,
      emailToSendNfse: null,
      nfseId: null,
      osToImport: null,
      selectedOS: null,
      keyToReRender: 0,
    };
  },
  mixins: [FormatMixin, SistemaMixin],
  components: {
    Tomador,
    Servicos,
    Pagamento,
    CheckBox,
    VueSelect,
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.getDataInit();
  },
  methods: {
    async getDataInit() {
      const responseOS = await this.$http.post("/ordemservico/os-autorizadas");
      this.osToImport = responseOS.data.sort((a, b) => {
        if (a.id_ordem_servico < b.id_ordem_servico) return -1;
        if (a.id_ordem_servico > b.id_ordem_servico) return 1;
        return 0;
      });
    },
    onSearchOS(search, loading) {
      loading(true);
      this.searchOS(loading, search, this);
    },
    searchOS: _.debounce(async (loading, search, vm) => {
      vm.osToImport = [];
      await vm.$nextTick();
      if (isNaN(parseInt(search))) {
        loading(false);
        return;
      }
      const response = await vm.$http.post("/ordemservico/os-autorizadas", {
        numero: parseInt(search),
      });
      vm.osToImport = _.cloneDeep(response.data);
      loading(false);
    }, 500),
    handleNext() {
      if (this.store.currentTab === 3) return;
      this.store.currentTab++;
    },
    async transmitirNFse() {
      this.addLoadingToButton("transmitir_nfse");
      try {
        if (this.dataFormattedToSend.parcelas.length === 0) {
          this.$toast.error("Insira ao menos um pagamento!");
          this.removeLoadingToButton("transmitir_nfse");
          return;
        }
        await this.$http.post(this.nfseId ? "/nfse/update" : "/nfse/create", {
          ...this.dataFormattedToSend,
          ["gerar_boleto"]: this.generateBoleto,
          ["enviar_email"]: true,
          ["email_nfse"]: this.emailToSendNfse,
          transmitir: true,
        });
        this.$toast.success("NFSe enviada para transmissão com sucesso!");
      } catch (error) {
        console.log(error);
      }
      this.$bvModal.hide("modalTransmitirNfse");
      this.emailToSendNfse = null;
      this.removeLoadingToButton("transmitir_nfse");
      this.$router.push({ name: "nfseServicos" });
    },
    async salvarNfse() {
      this.addLoadingToButton("submit_nova_nfse");
      const dataToSend = this.dataFormattedToSend;
      if (this.dataFormattedToSend.parcelas.length === 0) {
        this.$toast.error("Insira ao menos um pagamento!");
        this.removeLoadingToButton("submit_nova_nfse");
        this.store.informacoesComplementares = null;
        return;
      }
      try {
        await this.$http.post(
          this.nfseId ? "/nfse/update" : "/nfse/create",
          dataToSend
        );
        this.$toast.success("NFSe criada com sucesso!");
        this.$router.push({ name: "nfseServicos" });
        this.store.informacoesComplementares = null;
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("submit_nova_nfse");
    },
    handlePrevious() {
      if (this.store.currentTab === 0) return;
      this.store.currentTab--;
    },
    checkNutsDigital() {
      if (!this.nutsDigital) {
        return "Recurso disponível para quem possui a Nuts Digital, realize o seu cadastro";
      }

      return "Os boletos serão enviados para o e-mail do cliente, conforme está lançado na NFSe";
    },
    async importarOS() {
      this.addLoadingToButton("importar_os");
      try {
        const os = this.selectedOS.map(({ id_ordem_servico }) => ({
          id_ordem_servico,
        }));
        const response = await this.$http.post("/nfse/importar-os", {
          os,
        });

        this.store.os = os;
        const [
          formServicos,
          formTomador,
          formPagamento,
          informacoesComplementares,
        ] = this.extractOSByNFSeDataFromImport(response.data);
        this.store.formServicos = formServicos.map((servico, index) => ({
          ...servico,
          originalIndex: index,
        }));
        this.store.formTomador = formTomador;
        this.store.formTomador.endereco = formTomador.endereco[0];
        this.store.formTomador.emissao = this.thisDate();
        this.store.informacoesComplementares = informacoesComplementares;
        this.selectedOS = null;
        this.$toast.success("OS(s) importada(s) com sucesso!");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("importar_os");
      this.$bvModal.hide("importarOS");
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "nutsDigital",
    ]),
    ...mapState(["loading"]),
    canSaveNfse() {
      return this.store.currentTab === 2;
    },
    isUpdate() {
      return !!this.$route.params?.nfseData;
    },
    dataFormattedToSend() {
      return {
        ...(this.nfseId && { id_nfse: this.nfseId }),
        ...(this.store.os.length > 0 && {
          os: this.store.os,
        }),
        emissao: this.store.formTomador.emissao,
        id_pessoa: this.store.formTomador.id_pessoa,
        endereco: this.store.formTomador.endereco,
        realizar_reducao: this.store.formTomador.realizar_reducao ?? false,
        calcular_substituicao:
          this.store.formTomador.calcular_substituicao ?? false,
        servico: this.store.formServicos.map((servico) => ({
          ...servico,
          codigo: servico.codigoTributacaoMunicipio,
        })),
        parcelas: this.store.formPagamento.pagamentos.map((pagamento) => ({
          ...pagamento,
          ...pagamento.forma_pagamento,
          paymentMode: {
            description: pagamento.forma_pagamento.descricao,
            id: pagamento.forma_pagamento.id_meio_pagamento,
            name: pagamento.forma_pagamento.descricao,
          },
        })),
        total_nfse: this.store.formServicos.reduce(
          (acc, servico) => acc + servico.total,
          0
        ),
        ["gerar_boleto"]: false,
        ["enviar_email"]: false,
        ["email_nfse"]: null,
        transmitir: false,
        informacoes_complementares: this.store.informacoesComplementares,
        ...(this.store.formTomador.cep_prestado &&
          this.store.formTomador.prestacao_fora_municipio &&
          this.store.formTomador.cidade_prestada && {
            cep_prestado: this.store.formTomador.cep_prestado,
            tipo_bairro: "Bairro",
            bairro_prestado: this.store.formTomador.bairro_prestado,
            complemento_prestado: this.store.formTomador.complemento_prestado,
            numero_prestado: this.store.formTomador.numero_prestado,
            tipo_logradouro: "Rua",
            logradouro_prestado: this.store.formTomador.logradouro_prestado,
            cidade_prestada: this.store.formTomador.cidade_prestada,
            prestacao_fora_municipio:
              this.store.formTomador.prestacao_fora_municipio,
          }),
      };
    },
    isLoading() {
      return this.loading.loading;
    },
  },
  created() {
    this.store.currentTab = 0;
    this.nfseId = this.$route.params?.nfseData?.id_nfse;
  },
};
</script>